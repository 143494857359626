/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ExcelJS from 'exceljs';
import { MapsPin, View, Notification, Location, MenuScheduledAppointments, Tool, Calendar, CalendarService } from '../../../assets/icons';
import { Input, Modal, Pagination, Select, SelectSearch } from '../../../components'
import OrderDetail from './OrderDetail';
import { OperationService, ServiceService } from 'services';
import { Globals, Constants, Colors, Socket, socketEvents } from 'utils';
import { IList, IServiceType } from 'types';
import moment from 'moment'
import TechListTools from './TechListTools';
import TechList from './TechList';
import NextStepTechList from './NextStepTechList';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { on, emit } from 'jetemit';

interface IForm {
  init_date: string | undefined
  end_date: string | undefined
  service_id: string | null
  level_id: string | null
  client_id: string | null
  tech_id: string | null
  scheduled_type_id: string | null
};

const initialForm: IForm = {
  init_date: '',
  end_date: '',
  service_id: '',
  tech_id: '',
  level_id: '',
  client_id: '',
  scheduled_type_id: ''
};

interface IRevision {
  amount: number | undefined
  tech_percentage: number | undefined
};

const initRevision: IRevision = {
  amount: 0,
  tech_percentage: 0
};


const useFetch = () => {
  const { t } = useTranslation();
  const initialForm = {
    search: '',
    init_date: null,
    end_date: null,
    service_id: null,
    level_id: null,
    tech_id: null,
    client_id: null,
    page: 1,
    per_page: 20,
    last_page: 1,
    item_count: 0,
    scheduled_type_id: null
  };

  const initRevision = {
    amount: 0,
    tech_percentage: 0
  };

  const [data, setData] = useState<any[]>([]);
  const [revision_price, setRevisionPrice] = useState(initRevision);
  const [totals, setTotals] = useState({
    services_count: 0,
    services_money: 0,
    commissions_total: 0,
  })
  const [selects, setSelects] = useState<any>({
    techs: [],
    clients: [],
    services: [],
  });
  const [canFetch, setCanFetch] = useState(true);
  const [pagination, setPagination] = useState(initialForm);

  const debounceTime = 500;
  let debounce: any = null;

  const getCost = (item: any) => {
    const cost = parseFloat(item?.cost || 0);
    let partsCost = 0;
    if (!!item?.details?.length) {
      partsCost = item.details
        .reduce((prev: number, curr: any) => prev + (parseFloat(curr.cost) * curr.qty), 0);
    }
    return parseFloat((cost + partsCost).toFixed(2));
  }

  const getCostFromService = (item: any) => {
    const equipment_service = item.equipment?.equipment?.services;
    const filter = equipment_service?.filter(function (i: any) {
      return i.service_id == item.service_id;
    }) || [];
    const value = filter[0]?.cost_service || 0;
    return parseFloat(value).toFixed(2);
  }

  const getCommissionFromService = (item: any) => {
    const equipment_service = item.equipment?.equipment?.services
    const filter = equipment_service?.filter(function (i: any) {
      return i.service_id == item.service_id;
    }) || [];
    
    const value = filter[0]?.commission || 0;
    if(value > 0){
      return ((Number(value) * filter[0].cost_service) / 100).toFixed(2)
    }
    else{
      return Number(value).toFixed(2)
    }
    
  }

  const fetchData = async () => {
    if (!canFetch) return;

    try {
      const res: any = await OperationService.getPoster({
        page: pagination.page,
        per_page: pagination.per_page,
        scheduled_type_id: pagination.scheduled_type_id
      });

      if (!res?.data?.rows)
        return Globals.showError(t('common.globals.toast-error'));

      const last_page = res?.data?.count;
      setCanFetch(false);
      setPagination({
        ...pagination,
        item_count: res?.data?.count,
        last_page,
      });
      setData(res.data.rows);

    } catch (error) {
      console.log(error);
      Globals.showError(t('common.globals.toast-error'));
    }
  }

  const fetchToPrint = () => new Promise<any[]>((resolve, reject) => {
    OperationService.getPoster({
      page: pagination.page,
      per_page: pagination.item_count,
      scheduled_type_id: pagination.scheduled_type_id
    })
      .then((res: any) => {
        if (!res?.data?.orders?.rows)
          return [];

        setCanFetch(false);
        resolve(res.data.orders.rows);
      })
      .catch(reject);
  });

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setPagination(initialForm);
    fetchData();
  }

  const prevPage = () => {
    if (pagination.page > 1) {
      setCanFetch(true);
      setPagination({ ...pagination, page: pagination.page + 1 });
    }
  }

  const nextPage = () => {
    if (pagination.page < pagination.last_page) {
      setCanFetch(true);
      setPagination({ ...pagination, page: pagination.page + 1 });
    }
  }

  const goToPage = (page: number) => {
    if (page >= 1 && page <= pagination.last_page && page !== pagination.page) {
      setCanFetch(true);
      setPagination({ ...pagination, page });
    }
  }

  const search = (target: keyof IForm, value: string) => {
    if (debounce) clearTimeout(debounce);
    debounce = setTimeout(() => {
      setCanFetch(true);
      setPagination({ ...pagination, page: 1, [target]: value });
    }, debounceTime);
  }

  const forPrint = async () => {
    setCanFetch(true);
    const res = await fetchToPrint();
    return res || [];
  }

  useEffect(() => {
    fetchData();

    Socket.addEventListener(socketEvents.OPERATIONS.OPERATION_NEW_TECH, () => {
      fetchData();
    });

  }, [pagination]);

  return {
    orders: data,
    revision: revision_price,
    selects,
    totals,
    pagination,
    nextPage,
    prevPage,
    goToPage,
    reload,
    search,
    forPrint,
    getCost,
    getCostFromService,
    getCommissionFromService,
  }
}

const Poster = () => {
  const { t } = useTranslation();

  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState<IForm>(initialForm);
  const { orders, selects, totals, pagination, reload, goToPage, search, forPrint, getCost, getCostFromService, revision, getCommissionFromService } = useFetch();
  const [selectedItem, setSelectedItem] = useState<any>();
  const [techListToolsModal, setTechListToolsModal] = useState(false);
  const [techListModal, setTechListModal] = useState(false);
  const [nextStepTechListModal, setnextStepTechListModal] = useState(false);
  const [onSelectedTech, setOnSelectedTech] = useState([]);
  const [onTitleNextStep, setTitleNextStep] = useState<any>();
  const [scheduleTypeOptions, setScheduleTypeOptions] = useState([
    { value: Constants.SCHEDULED_TYPES.SCHEDULED_VISIT.toString(), label: i18n.t('common.globals.immediately') },
    { value: Constants.SCHEDULED_TYPES.SCHEDULED_MAINTENANCE.toString(), label: i18n.t('common.globals.scheduled') }
  ]);

  const onChange = (target: keyof IForm, value: any) => {
    setFormData(s => ({ ...s, [target]: value }));
    search(target, (value?.value || value));
  }

  const resetForm = () => {
    setFormData(initialForm);
    reload();
  }

  const viewItem = (item: any) => {
    setSelectedItem(item);
    setModal(true);
  }

  const viewTechListTools = (item: any) => {
    setSelectedItem(item);
    setTechListToolsModal(true);
  }

  const viewTechList = () => {
    setTechListModal(true);
  }

  const onCloseModal = (reloading: boolean = false) => {
    if (reloading) reload();
    setModal(false);
    setTechListToolsModal(false);
    setTechListModal(false);
  }

  const openMaps = (data: any) => {
    if (!data?.property?.latitude || !data?.property?.longitude)
      return Globals.showError(t('common.globals.property.property_location_not_found'));

    window.open(`https://google.com/maps?q=${data?.property?.latitude},${data?.property?.longitude}&z=16`,'_blank');
  }

  useEffect(() => {
    on(socketEvents.LANG.UPDATE, () => {
      setScheduleTypeOptions([
        { value: Constants.SCHEDULED_TYPES.SCHEDULED_VISIT.toString(), label: i18n.t('common.globals.immediately') },
        { value: Constants.SCHEDULED_TYPES.SCHEDULED_MAINTENANCE.toString(), label: i18n.t('common.globals.scheduled') }
      ])
    });
  }, [])

  return (
    <div id="admin-poster-services">
      <Modal
        title={t('common.billboard.details')}
        titleClassName="text-white"
        size="md"
        visible={modal}
        onClose={() => onCloseModal(false)}
        closeButton={true}
        customCloseButton={true}
        headerStyle={{ background: Colors.blue }}
      >
        <OrderDetail item={selectedItem} onClose={onCloseModal} />
      </Modal>

      <Modal
        title={t('common.billboard.technician-list')}
        titleClassName="text-white"
        size="lg"
        visible={techListToolsModal}
        onClose={() => onCloseModal(false)}
        headerStyle={{ background: Colors.blue }}
        closeButton={true}
        customCloseButton={true}
      >
        <TechListTools item={selectedItem} onClose={onCloseModal} />
      </Modal>

      <Modal
        title={t('common.billboard.technician-list')}
        titleClassName="text-white"
        size="lg"
        visible={techListModal}
        onClose={() => onCloseModal(false)}
        headerStyle={{ background: Colors.blue }}
        closeButton={true}
        customCloseButton={true}
      >
        <TechList
          onUserSelected={(data: []) => {
            setOnSelectedTech(data)
            const title = data.map((item: any) => `${item?.name} ${item?.lastname}`);
            setTitleNextStep(title.join(', '));
            setTechListModal(false)
            setnextStepTechListModal(true);
          }}
        />
      </Modal>

      <Modal
        title={t('common.billboard.new-message')}
        titleClassName="text-white"
        size="lg"
        visible={nextStepTechListModal}
        onClose={() => setnextStepTechListModal(false)}
        headerStyle={{ background: Colors.blue }}
        closeButton={true}
        customCloseButton={true}
      >
        <NextStepTechList
          item={onSelectedTech}
          onClose={() => {
            setnextStepTechListModal(false)
          }}
        />
      </Modal>

      <div className="search-bar">
        <div className="col col-3">
          <Select
            label={t('common.billboard.appointment-type') || ''}
            name="scheduled_type_id"
            options={scheduleTypeOptions}
            value={formData.scheduled_type_id}
            onChange={v => onChange('scheduled_type_id', v)}
          />
        </div>
        <div className="col col-3" style={{ display: 'flex', alignItems: 'flex-end' }}>
          <button
            type="button"
            className="btn--primary" style={{ width: '100%', marginBottom: '1.1rem', marginRight: '1rem', backgroundColor: 'gray' }}
            onClick={() => resetForm()}
          >
            <span>{t('common.globals.clear_filters')}</span>
          </button>
        </div>
      </div>

      <div className="custom-table">
        <div className="header">
          <div>
            <div className="">{t('common.billboard.order_request')}</div>
          </div>
          <div className="actions">
            <button className="btn--primary" onClick={viewTechList}>
              <img src={Notification} width={24} style={{ marginRight: 10 }} />
              <span>{t('common.billboard.tech_notify')}</span>
            </button>
          </div>
        </div>

        {!!orders.length ? (
          <table>
            <thead>
              <tr>
                <th>{t('common.operations.commissions.service_date')}</th>
                <th>{t('common.billboard.client')}</th>
                <th>{t('common.billboard.appointment-type')}</th>
                <th>{t('common.billboard.phone')}</th>
                <th>{t('common.billboard.email')}</th>
                <th>{t('common.globals.location')}</th>
                <th>{t('common.globals.cost')}</th>
                <th className="text-right">{t('common.billboard.actions')}</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((i, index) => (
                <tr key={`row-${index}`}>
                  <td>{moment(i.date || i.created_at).format('DD-MM-YYYY HH:mm')}</td>
                  <td>{i.client?.name}</td>
                  <td>{i?.scheduled_type?.name}</td>
                  <td>{i?.client?.person?.phone}</td>
                  <td>{i.client?.email}</td>
                  <td>
                    <img
                      className="action-icon cursor-pointer"
                      src={Location}
                      alt="Ver servicio"
                      onClick={() => openMaps(i)}
                    />
                  </td>
                  <td>{getCost(i)}</td>
                  <td className="row-actions right">
                    {
                      i?.scheduled_type_id === Constants.SCHEDULED_TYPES.SCHEDULED_MAINTENANCE && (
                        <img
                          className="action-icon cursor-pointer"
                          src={CalendarService}
                          alt="Ver servicio"
                          onClick={() => viewItem(i)}
                        />
                      )
                    }
                    <img
                      className="action-icon cursor-pointer"
                      src={Tool}
                      alt="Ver servicio"
                      onClick={() => viewTechListTools(i)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="no-items">{t('common.billboard.not_data_found')}</div>
        )}
      </div>

      <div className="wrapped">
        <Pagination
          active={pagination.page}
          pages={pagination.last_page}
          onChange={goToPage}
        />
      </div>
    </div>
  )
}

export default Poster;