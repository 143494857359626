/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Send } from 'assets/icons';
import { RootState } from 'reducers';
import { ChatService } from 'services';
import { Globals, Socket, socketEvents } from 'utils';
import { Add } from 'assets/icons';
import { Modal } from 'components';
import CreateChat from './CreateChat';
import NotificationSound from 'assets/sounds/notification.mp3';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

interface MessagesProps {
  id: number,
  sender_id: number,
  chat_session_id: number,
  message: string,
  mine?: boolean
}

interface ListItemProps {
  item: any
  isSelected: boolean
  showBadge: boolean
  onClick: () => void
}

interface MessageItemProps {
  id?: number
  isSender: boolean
  textMessage: string
  date: string | any
}

interface InputBoxProps {
  chat_session_id: number
  sender_id: number
}

let canSend = true;
let isMounted = true;

const useFetch = (user: any) => {
  const [chats, setChats] = useState<MessagesProps[]>([]);
  const [logs, setLogs] = useState<any[]>([]);

  const getChats = async () => {
    const data = await ChatService.getChats({ user_id: user?.id });
    const format = data as { chats: [] };

    setChats(format?.chats);
  }

  const getLogs = async (chat_session_id: number) => {
    const data = await ChatService.getLogs({ chat_session_id });
    const format = data as { chats: [] };

    setLogs(format?.chats);
  }

  useEffect(() => {
    getChats();

    return () => {
      isMounted = false;
    }
  }, []);

  return {
    chats,
    getChats,
    logs,
    getLogs,
    setLogs,
  }
}

const Chat = () => {
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user);
  const { chats, getChats, logs, getLogs, setLogs } = useFetch(user);
  const [modal, setModal] = useState(false);
  const [selectedChat, setSelectedChat] = useState<number>();
  const [badge, setBadge] = useState<number[]>([]);

  useEffect(() => {
    if (!!selectedChat) {
      // Establece el pulse en el ListItem del chat sin leer
      if (badge.includes(selectedChat))
        setBadge(s => s.filter(x => x !== selectedChat));

      const currentChat: any = chats.find((x: any) => x.item.chat_session_id === selectedChat);

      // Marca como leida la conversación seleccionada
      if (!currentChat?.item?.viewed) {
        Socket.emit(socketEvents.CHAT.VIEWED, {
          chat_session_id: selectedChat,
          user_id: user?.id,
        });
      }
    }
  }, [selectedChat]);

  useEffect(() => {
    Socket.addEventListener(socketEvents.CHAT.NEW_MESSAGE, (data: any[] = []) => {
      console.log('AAB')
      const isSameActive = data.some((x: any) => x.chat_session_id === selectedChat);
      const sessionId = !!data?.length ? data[0]?.chat_session_id : 0;

      const notify = () => {
        const audio = new Audio(NotificationSound);
        audio.play();
        setBadge(s => ([ ...s, sessionId ]));
      }

      setLogs(data);
      notify();
      /*if (isMounted) {
        if (isSameActive) setLogs(data);
        else notify();
      }*/
    });
    Socket.addEventListener(socketEvents.CHAT.NEW_CHAT, () => {
      //if (isMounted) getChats();
      getChats();
    });
    Socket.addEventListener(socketEvents.CHAT.VIEWED, (data: any) => {
      const exists = chats.some((x: any) => x.item.chat_session_id === data.chat_session_id);
      if (isMounted && exists) getChats();
    });

    return () => {
      Socket.removeEventListener(socketEvents.CHAT.NEW_CHAT);
      Socket.removeEventListener(socketEvents.CHAT.NEW_MESSAGE);
      Socket.removeEventListener(socketEvents.CHAT.VIEWED);
    }
  }, [chats, selectedChat]);

  return (
    <div id="chat">
      {
        modal && (
          <Modal
            title={t('common.chat.new_chat')}
            visible={modal}
            onClose={() => setModal(false)}
          >
            <CreateChat onClose={() => setModal(false)} user={user}/>
          </Modal>
        )
      }
      <div className="list">
        {chats.map((value: any, index: number) => (
          <ListItem
            key={`list-item-${index}`}
            item={value}
            isSelected={selectedChat === value?.item.chat_session_id}
            showBadge={!value?.viewed || badge.includes(value?.item?.chat_session_id)}
            onClick={() => {
              if (selectedChat === value?.item.chat_session_id) {
                setSelectedChat(undefined);
              } else {
                setSelectedChat(value?.item.chat_session_id);
                getLogs(value?.item.chat_session_id);
              }
            }}
          />
        ))}
      </div>
      {
        selectedChat ? (
          <Messages chats={logs} user={user} chat_session_id={selectedChat} />
        ) : (
          <div className="add-box">
            <div className="addButton">
              <div className='btn-style' onClick={() => setModal(true)}>
                <img src={Add} alt='' />
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

const ListItem = (props: ListItemProps) => {
  const { t } = useTranslation();
  const isSelected = props.isSelected ? ' selected' : '';
  const photo = props.item?.item?.sender?.person?.photo
  const showPhoto = photo ? Globals.fromPhotos(photo) : Globals.fromPhotos('user.png');

  return (
    <div className={`list-item${isSelected}`} onClick={() => props.onClick()}>
      <div className="photo">
        <img
          src={showPhoto}
          alt='Avatar'
        />
      </div>
      <div className="info">
        <div className="group">
          <div className="name">{`${props.item?.item.sender?.name} ${props.item?.item.sender?.lastname}`}</div>
          <div className="time">{moment(props.item?.lastDateMessage).format('DD-MM-YYYY h:mma')}</div>
        </div>
        <div className="last-message">{props.item?.lastMessage}</div>
      </div>
      {props.showBadge && <div className="circle pulse" />}
    </div>
  );
}

const Messages = ({ chats, user, chat_session_id }: any) => {
  const { t } = useTranslation();
  const getChats: [] = chats.map((item: unknown) => {
    const data = item as MessagesProps;
    data.mine = data.sender_id === user?.id ? true : false;

    return data;
  });

  const messagesRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState(getChats);

  const goToBottom = () => {
    messagesRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  }

  useEffect(() => {
    goToBottom();
  }, [messages])

  if (chats.length <= 0) return (
    <div className="conversation">
      <div className="not-selected">
        {t('common.chat.first_message')}
      </div>
      <InputBox
        chat_session_id={chat_session_id}
        sender_id={user.id}
      />
    </div>
  );

  return (
    <div className="conversation">
      <div className="messages">
        {getChats.map((item: any, index) => (
          <MessageItem
            key={`message-item-${index}`}
            isSender={item.mine}
            textMessage={item.message}
            date={item.created_at}
          />
        ))}
        <div ref={messagesRef} />
      </div>
      <InputBox
        chat_session_id={chat_session_id}
        sender_id={user.id}
      />
    </div>
  );
}

const MessageItem = (props: MessageItemProps) => {

  const isSender = props.isSender ? ' is-sender' : '';

  return (
    <div className={`message-item${isSender}`}>
      <div className="text">
        {props.textMessage}
        <span className="hidden-time">
          {moment(props.date).format('DD-MM-YYYY h:mma')}
        </span>
      </div>
      <div className="time">{moment(props.date).format('DD-MM-YYYY h:mma')}</div>
    </div>
  );
}

const InputBox = (props: InputBoxProps) => {

  const [formData, setFormData] = useState({
    textMessage: '',
  });

  const onChange = (target: keyof typeof formData, value: string) => {
    setFormData(s => ({ ...s, [target]: value }))
  }

  const submit = (event?: React.FormEvent) => {
    event?.preventDefault();
    if (!canSend || !formData.textMessage) return;
    canSend = false;

    // TODO: Conectar con el backend

    Socket.emit(socketEvents.CHAT.NEW_MESSAGE, {
      sender_id: props.sender_id,
      message: formData.textMessage,
      session_id: props.chat_session_id,
    });

    canSend = true;
    onChange('textMessage', '');
  }

  return (
    <form className="input-box" onSubmit={submit}>
      <input
        value={formData.textMessage}
        onChange={v => onChange('textMessage', v.target.value)}
      />
      <button className="send" type="submit" onClick={() => submit()}>
        <img src={Send} alt="Enviar" />
      </button>
    </form>
  );
}

export default Chat;