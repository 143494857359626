/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Input } from 'components';
import { Globals } from 'utils';
import { AppointmentsService } from 'services';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

interface CreateEquipmentProps {
  onClose: (reloading: boolean) => void
  order_id: number
}

interface IForm {
  date: string
  time: string
}

const initialForm: IForm = {
  date: '',
  time: '',
};

const AppointmentTime = ({ onClose, order_id }: CreateEquipmentProps) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<IForm>(initialForm);

  const onChange = (target: keyof typeof formData, value: string) => {
    setFormData(s => ({ ...s, [target]: value }))
  }

  const submit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!isValidForm()) return;

    try {
      const date = `${formData.date} ${formData.time}:00`;
      const res: any = await AppointmentsService.updateAppointment({
        order_id,
        date
      })

      if (res?.error) return Globals.showError(res.error);

      Globals.showSuccess(`${t('common.operations.schedule.date_edited')} ${moment(date).format('DD-MM-YYYY h:mm a')}`);
      onClose(true);

    } catch (error) {
      console.log(error);
      Globals.showError();
    }
  }

  const isValidForm = (): boolean => {
    const onError = (msg: string) => {
      Globals.showError(msg);
      return false;
    }

    if (!formData.date)
      return onError(t('common.operations.schedule.form.date'));

    if (!formData.time)
      return onError(t('common.operations.schedule.form.hour'));

    return true;
  }

  return (
    <div id="admin-sched-appo-modal-app-time">
      <form onSubmit={submit}>
        <div className="form--group">
          <div style={{ flex: 1 }}>
            <Input
              type="date"
              label={t('common.globals.date') || ''}
              value={formData.date}
              onChange={v => onChange('date', v)}
            />
          </div>
          <div style={{ flex: 1 }}>
            <Input
              type="time"
              label={t('common.globals.hour') || ''}
              value={formData.time}
              onChange={v => onChange('time', v)}
            />
          </div>
        </div>
        <div className="actions">
          <button className="form--submit-button" type="submit">
            <span>{t('common.globals.save')}</span>
          </button>
        </div>
      </form>
    </div>
  )
}

export default AppointmentTime;