import React, { useEffect, useState } from 'react';
import { MapsPin } from 'assets/icons';
import { Constants, Globals, socketEvents, Socket } from 'utils';
import { TechService } from 'services';
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

type EventType = number;

interface IAppointmentDetails {
  eventType: EventType
  onClose: (reloading: boolean) => void
  data: any,
  onReload: (reload: true) => void
}

var requestExpire: any;

const AppointmentDetails = ({ eventType, onClose, data, onReload }: IAppointmentDetails) => {
  const { t } = useTranslation();
  const [round, setRound] = useState<number>(0);
  const [dataSelected, setDataSelected] = useState<any>({});
  const [disabledRequestClient, setDisabledRequestClient] = useState<boolean>(false);
  const [payment, setPayment] = useState<boolean>(false);

  const openMaps = () => {
    console.log(data)
    if (!data?.property?.latitude || !data?.property?.longitude)
      return Globals.showError(t('common.globals.property.property_location_not_found'));

    window.open(`https://google.com/maps?q=${data?.property?.latitude},${data?.property?.longitude}&z=16`,'_blank');
  }

  const loadSockets = () => {
    Socket.addEventListener(socketEvents.TECH.TECH_ACCEPTED, (accepted: any) => {
      if (accepted?.order_id === data?.id) {
        const tech = accepted?.res?.tech;
        setDataSelected({
          ...data,
          tech
        });
        clearTimeout(requestExpire);
        setRound(0);
        Globals.showSuccess(t('common.operations.schedule.tech_accepted'));
      }
    });

    Socket.addEventListener(socketEvents.TECH.TECH_EXPIRED, (expired: any) => {
      if (expired?.order_id === data?.id) {
        if (round < 1) {
          const newRound = round + 1;
          setRound(newRound);
          if (newRound < 2) {
            Globals.confirm(t('common.globals.try_again'), () => {
              sendRequest();
            })
          }
        } else {
          clearTimeout(requestExpire);
          setRound(0);
        }
      }
    });

    Socket.addEventListener(socketEvents.CLIENT.CLIENT_ANUAL_REQUEST, () => {
      setDisabledRequestClient(true);
    });

    Socket.addEventListener(socketEvents.CLIENT.CLIENT_PAYMENT, (data: any) => {
      setPayment(true);
      onReload(true);
    })
  }

  const sendRequest = async () => {
    const techs: [] = await searcherTech();
    if (techs.length > 0) {
    
      Socket.emit(socketEvents.TECH.TECH_SEARCHER, {
        client_id: data?.client?.id,
        techs_ids: techs,
        type: data?.technical_service?.type_id || Constants.CALL_TYPE.URGENT,
        payment_method: data?.technical_service?.payment_method_id,
        order_id: data?.id,
        scheduled_form: {
          date: dataSelected?.date,
          equipment_id: dataSelected?.equipment_id,
          hour: moment(dataSelected?.dateWithHour).format('h'),
          minutes: moment(dataSelected?.dateWithHour).format('mm a'),
          property_id: dataSelected?.property_id,
          user_id: dataSelected?.client?.id,
          hideClientModal: true
        }
      });
      requestExpire = setTimeout(() => {
        Socket.emit(socketEvents.TECH.TECH_EXPIRED, {
          techs_ids: techs,
          client_id: data?.client?.id,
          order_id: data?.id
        })
      }, 30000);
    }
  }
  const sendAnualRequest = async () => {
    const service = dataSelected?.service?.equipments_services?.find((x: any) =>(x?.service_id === dataSelected?.service?.id && x?.equipment_id === dataSelected?.equipment?.equipment?.id))
    console.log({
      property: dataSelected?.property,
      service,
      equipment: dataSelected?.equipment,
      client_id: dataSelected?.client?.id,
      order_id: data?.id,
      date: dataSelected?.dateWithHour
    }, ' SOCKET SEND ANUAL REQUEST ')
    Socket.emit(socketEvents.CLIENT.CLIENT_ANUAL_REQUEST, {
      property: dataSelected?.property,
      service,
      equipment: dataSelected?.equipment,
      client_id: dataSelected?.client?.id,
      order_id: data?.id,
      date: dataSelected?.dateWithHour
    });
  }

  const searcherTech = async () => {
    const res: any = await TechService.search_techs({ user_id: data?.client?.id });
    return res?.techs;
  }

  useEffect(() => {
    setDataSelected(data);
    if (data.payment) {
      setPayment(true)
    }

    loadSockets();

    return () => {
      Socket.removeEventListener(socketEvents.TECH.TECH_EXPIRED);
      Socket.removeEventListener(socketEvents.TECH.TECH_ACCEPTED);
      Socket.removeEventListener(socketEvents.CLIENT.CLIENT_ANUAL_REQUEST);
      Socket.removeEventListener(socketEvents.CLIENT.CLIENT_PAYMENT);
    }
  }, []);

  // const property_equipment = data?.property?.properties_equipments.find((item: any) => {
  //   if (
  //     (item?.property_id === data?.property?.id) &&
  //     (item?.equipment_id === data?.equipment_id)
  //   ) {
  //     return item;
  //   }
  // });

  return (
    <div id="admin-sched-appo-modal-appo-details" className="content">

      <div className="owner">
        <p>{data?.client?.level?.name}</p>
        <p className="text-center">{data?.client?.person?.phone}</p>
        <p>{data?.client?.email}</p>
      </div>

      {data?.client?.level_id === Constants.USER.LEVEL.PROPERTY_ADMIN ? (
        <>
          <div className="divider" />

          <p className="company-label">{t('common.globals.company.company_data')}</p>
          <div className="company">
            <p>{data?.client?.enterprise?.name}</p>
            <p className="text-center">{data?.client?.enterprise?.phone}</p>
            <p>{data?.client?.enterprise?.email}</p>
          </div>

          <p className="location-label">{t('common.globals.location')}</p>
          <p>{data?.client?.person?.address}</p>

          <div style={{ display: 'flex', marginBottom: '1.5rem' }} className='row'>
            <div className='col-md-6 col-12 col-sm-12 col-lg-6'>
              <button className="maps-location" onClick={openMaps}>
                <img src={MapsPin} alt="Google Maps Pin" />
                <span>{t('common.globals.show_location')}</span>
              </button>
            </div>
            <div className='col-md-6 col-12 col-sm-12 col-lg-6'>
              {t('common.globals.payment_status')} <br />
              <span style={{
                backgroundColor: dataSelected.payment || payment ? '#4bdb84' : '#e53935',
                padding: 5,
                color: 'white',
                borderRadius: 13
              }}>{dataSelected.payment || payment ? t('common.globals.paid') : t('common.globals.pending_payment')}</span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div style={{ display: 'flex', marginBottom: '2rem', marginTop: '1rem' }} className='row'>
            <div className='col-12'>
              {t('common.globals.payment_status')} <br />
              <span style={{
                backgroundColor: dataSelected.payment || payment ? '#4bdb84' : '#e53935',
                padding: 5,
                color: 'white',
                borderRadius: 13
              }}>{dataSelected.payment || payment ? t('common.globals.paid') : t('common.globals.pending_payment')}</span>
            </div>
          </div>
        </>
      )}

      <div className="divider" />
      {
        (data?.tech || dataSelected?.tech) && (
          <>
            <p className="technician-label">{t('common.globals.tech_data')}</p>
            <div className="technician">
              <p>
                {data?.tech?.name || dataSelected?.tech?.name} {data?.tech?.lastname || dataSelected?.tech?.lastname}
                <div className="stars">
                {[...Array(Math.round(data?.tech?.person.calification || dataSelected?.tech?.person?.calification || 0))].map((x, i) => {
                  return i + 1 > (data?.tech?.person.calification || dataSelected?.tech?.person?.calification || 0)
                    ? <i key={`star-${data?.tech?.id || dataSelected?.tech?.id}-${i}`} className="fa fa-star-half"></i>
                    : <i key={`star-${data?.tech?.id || dataSelected?.tech?.id}-${i}`} className="fa fa-star"></i>
                })}
              </div>
              </p>
              <p className="text-center">{data?.tech?.person?.phone || dataSelected?.tech?.person?.phone}</p>
              <p>{data?.tech?.email || dataSelected?.tech?.email}</p>
            </div>
          </>
        )}

      <table>
        <thead>
          <tr>
            <th>{t('common.globals.date')}</th>
            <th>{t('common.globals.hour')}</th>
            <th>{t('common.globals.property.property_data')}</th>
            <th>{t('common.globals.equipment')}</th>
            <th>{t('common.globals.service_type')}</th>
            <th>{t('common.globals.cost')}</th>
          </tr>
        </thead>
        <tbody>
          <tr key={`row-property`}>
            <td>{data?.date}</td>
            <td>{moment(data?.dateWithHour).format('h:mm a')}</td>
            <td>{data?.property?.name}</td>
            <td>{data?.equipment?.equipment?.name}</td>
            <td>{data?.service?.name || 'Revisión'}</td>
            <td>{data?.cost}</td>
          </tr>
        </tbody>
      </table>
      <div className='row'>
        <div className='col-12 col-sm-12 col-lg-6 col-md-6 submit'>
          <button
            onClick={sendAnualRequest}
            disabled={disabledRequestClient || payment}
            style={{
              backgroundColor: disabledRequestClient || payment ? 'gray' : '#4bdb84'
            }}
          >{t('common.globals.send_request_client')}</button>
        </div>
        {
          (!data?.tech || !dataSelected?.tech) && (
            <div className="col-12 col-sm-12 col-lg-6 col-md-6 submit">
              <button
                onClick={sendRequest}
                disabled={!payment}
                style={{ background: payment ? '#4bdb84' : 'gray' }}
              >
                {t('common.globals.send_request_tech')}
              </button>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default AppointmentDetails;