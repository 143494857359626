/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { DownloadIcon, Report, Search } from 'assets/icons';
import { Input, Modal, Pagination } from 'components';
import OperationDetails from './OperationDetails';
import { OperationService, TechService } from 'services';
import { Constants, Globals, Socket, socketEvents } from 'utils';
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

interface IForm {
  search: string
  init_date: string | undefined
  end_date: string | undefined
  property: string
  technical: string
};

const initialForm: IForm = {
  search: '',
  init_date: undefined,
  end_date: undefined,
  property: '',
  technical: '',
};

const debounceTime = 500;
let debounce: any = null;

const useFetch = () => {
  const { t } = useTranslation();
  const initialForm = {
    search: '',
    page: 1,
    per_page: 20,
    last_page: 1,
    item_count: 0,
    init_date: null,
    end_date: null,
    property: '',
    technical: '',
  };

  const initRevision = {
    amount: 0,
    tech_percentage: 0
  };

  const [data, setData] = useState<any[]>([]);
  const [revision_price, setRevisionPrice] = useState(initRevision);
  const [canFetch, setCanFetch] = useState(true);
  const [pagination, setPagination] = useState(initialForm);

  const fetchData = async () => {
    if (!canFetch) return;

    const status = (Object.values(Constants.SERVICES.STATUS))
      .map(item => item.ID)
      .filter(x => 
        (x !== Constants.SERVICES.STATUS.PENDING_ASSIGNING.ID) &&
        (x !== Constants.SERVICES.STATUS.FINISHED.ID) &&
        (x !== Constants.SERVICES.STATUS.CANCELLED.ID) &&
        (x !== Constants.SERVICES.STATUS.DENIED.ID)
      );

    try {
      const res: any = await OperationService.get({
        page: pagination.page,
        per_page: pagination.per_page,
        search: pagination.search,
        init_date: pagination.init_date,
        end_date: pagination.end_date,
        property: pagination.property,
        technical: pagination.technical,
        status,
        withoutLoading: true
      });

      if (!res?.data?.orders?.rows)
        return Globals.showError(t('common.globals.toast-error'));

      const last_page = res?.data?.orders?.count;
      setCanFetch(false);
      setPagination({
        ...pagination,
        item_count: res?.data?.orders?.count,
        last_page,
      });
      setData(res.data.orders.rows);
      setRevisionPrice(res.data.revision)

    } catch (error) {
      console.log(error);
      Globals.showError(t('common.globals.toast-error'));
    }
  }

  const listenToRequests = () => {
    Socket.addEventListener(socketEvents.TECH.TECH_ACCEPTED, () => {
      reload(true)
    });
    Socket.addEventListener(socketEvents.OPERATIONS.OPERATION_UPDATE, () => {
      reload(true)
    });
    Socket.addEventListener(socketEvents.OPERATIONS.OPERATION_ACCEPTED_ORDER, () => {
      reload(true)
    })
  }

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setPagination(initialForm);
    fetchData();
  }

  const prevPage = () => {
    if (pagination.page > 1) {
      setCanFetch(true);
      setPagination({ ...pagination, page: pagination.page + 1 });
    }
  }

  const nextPage = () => {
    if (pagination.page < pagination.last_page) {
      setCanFetch(true);
      setPagination({ ...pagination, page: pagination.page + 1 });
    }
  }

  const goToPage = (page: number) => {
    if (page >= 1 && page <= pagination.last_page && page !== pagination.page) {
      setCanFetch(true);
      setPagination({ ...pagination, page });
    }
  }

  const search = (search: string, field: string = 'search') => {
    if (debounce) clearTimeout(debounce);
    debounce = setTimeout(() => {
      setCanFetch(true);
      setPagination(s => ({ ...s, page: 1, [field]: search }));
    }, debounceTime);
  }

  useEffect(() => {
    fetchData();
  }, [pagination]);

  useEffect(() => {
    listenToRequests();
  }, []);

  return {
    operations: data,
    pagination,
    nextPage,
    prevPage,
    goToPage,
    reload,
    search,
    revision: revision_price,
  }
}

const Operations = () => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState<IForm>(initialForm);
  const { operations, pagination, reload, goToPage, search, revision } = useFetch();
  const [selectedService, setSelectedService] = useState<any>();

  const viewService = (user: any) => {
    setSelectedService(user);
    setModal(true);
  }

  const downloadInvoice = (service: any) => {
    OperationService.historyServicePDF({ order_id: service?.id, bill: true })
      .then((res: any) => {
          const url = Globals.fromPhotos(res.quotation);
          const element = document.createElement('a');
          element.href = url;
          element.download = t('common.globals.service') + ' ' + service?.id;
          element.target = '_blank';
          element.click();
      });
  }

  const searchTech = async (op: any) => {
    try {
      const res: any = await TechService.getAvailableTechs({ user_id: op.client_id });
      if (!res?.techs?.length)
        return Globals.showError(t('common.globals.not_technicians_found'));

      Globals.showSuccess(t('common.globals.request_sended'));

      Socket.emit(socketEvents.TECH.TECH_SEARCHER, {
        techs_ids: res.techs,
        client_id: op.client_id,
        service: op,
        order_id: op.id
      });

    } catch (error) {
      console.log(error)
    }
  }

  const onCloseModal = (reloading: boolean) => {
    if (reloading) reload();
    setModal(false);
  }

  const onChange = (target: keyof IForm, value: any) => {
    setFormData(s => ({ ...s, [target]: value }));
    search(value, target);
  }

  const resetForm = () => {
    setFormData(initialForm);
    reload();
  }

  return (
    <div id="admin-operations">
      <Modal
        title={`${selectedService?.client?.name || ''} ${selectedService?.client?.lastname || ''}`}
        titleClassName="text-blue"
        size="xl"
        visible={modal}
        onClose={() => onCloseModal(false)}
      >
        <OperationDetails service={selectedService} revision={revision} />
      </Modal>

      <div className="search-bar">
        <div className="col col-12 col-sm-12 col-lg-4">
          <Input
            filterStyle
            leftIcon={Search}
            noMargin
            value={formData.search}
            placeholder={ t('common.billboard.name') }
            label={t('common.operations.client') || ''}
            onChange={v => onChange('search', v)}
          />
        </div>
        <div className="col col-12 col-sm-12 col-lg-4">
          <Input
            filterStyle
            noMargin
            value={formData.init_date}
            type="date"
            label={t('common.globals.since') || ''}
            onChange={v => onChange('init_date', v)}
          />
        </div>
        <div className="col col-12 col-sm-12 col-lg-4">
          <Input
            filterStyle
            noMargin
            value={formData.end_date}
            type="date"
            label={t('common.globals.until') || ''}
            onChange={v => onChange('end_date', v)}
          />
        </div>
        <div className="col col-12 col-sm-12 col-lg-4">
          <Input
            filterStyle
            noMargin
            value={formData.property}
            label={t('common.globals.property.property_data') || ''}
            placeholder={t('common.globals.search_by_property') || ''}
            onChange={v => onChange('property', v)}
          />
        </div>
        <div className="col col-12 col-sm-12 col-lg-4">
          <Input
            filterStyle
            noMargin
            value={formData.technical}
            label={t('common.globals.tech') || ''}
            placeholder={ t('common.billboard.name') }
            onChange={v => onChange('technical', v)}
          />
        </div>
        <div className="col col-3" style={{ display: 'flex', alignItems: 'flex-end' }}>
          <button
            type="button"
            className="btn--primary" style={{ width: '100%', marginBottom: '1px', marginRight: '1rem', backgroundColor: 'gray' }}
            onClick={() => resetForm()}
          >
            <span>{t('common.globals.clear_filters')}</span>
          </button>
        </div>
      </div>

      <div className="custom-table">
        <div className="header">
          <span className="title">{t('common.operations.data-registered')}</span>
        </div>
        {!!operations.length ? (
          <table>
            <thead>
              <tr>
                <th>{t('common.operations.service_number')}</th>
                <th>{t('common.operations.schedule_date')}</th>
                <th>{t('common.operations.client')}</th>
                <th>{t('common.operations.property')}</th>
                <th>{t('common.operations.assignment_tech')}</th>
                <th>{t('common.operations.init_date')}</th>
                <th>{t('common.operations.status')}</th>
                <th>{t('common.operations.actions')}</th>
              </tr>
            </thead>
            <tbody>
              {operations.map((op, index) => (
                <tr key={`row-${index}`}>
                  <td>{String(op?.code || op?.id).padStart(6, '0')}</td>
                  <td>{moment(op.date || op.created_at).format('DD-MM-YYYY HH:mm')}</td>
                  <td>{op.client?.name} {op.client?.lastname}</td>
                  <td>
                    {op?.property?.name}
                  </td>
                  <td>{op.tech?.name} {op.tech?.lastname}</td>
                  <td>{moment(op?.start_time).isValid() && moment(op?.start_time).format('DD-MM-YYYY HH:mm')}</td>
                  <td className={`
                    ${op.status === Constants.SERVICES.STATUS.INITIATED.ID && 'text-success'}
                    ${op.status === Constants.SERVICES.STATUS.DENIED.ID && 'text-danger'}
                  `}>
                    {Globals.getServiceStatusName(op.status)}
                  </td>
                  <td className="row-actions">
                    <img
                      className="action-icon"
                      src={Report}
                      alt="Action icon"
                      title={t('common.globals.view_service') || ''}
                      onClick={() => viewService(op)}
                    />
                    <img
                      className="action-icon"
                      src={DownloadIcon}
                      alt="Action icon"
                      title={t('common.globals.download_invoice') || ''}
                      onClick={() => downloadInvoice(op)}
                    />
                    {!op.tech && (
                      <i
                        className="fa fa-user"
                        style={{ fontSize: 20, cursor: 'pointer' }}
                        title={t('common.globals.search_tech') || ''}
                        onClick={() => searchTech(op)}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="no-items">{t('common.operations.not_data_found') || ''}</div>
        )}
      </div>
      <Pagination
        active={pagination.page}
        pages={pagination.last_page}
        onChange={goToPage}
      />
    </div>
  )
}

export default Operations;