/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Input, Pagination, Select, Checkbox, Button } from 'components';
import { OperationService, ServiceService } from 'services';
import { Constants, Globals, Colors, Socket, socketEvents } from 'utils';
import { IList, IServiceType } from 'types';
import { CalendarService, Location, LocationIcon, Notification, PhoneIcon, RecoveryEmail, Search, UserNoPhoto } from 'assets/icons';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

interface Props {
  item?: any
  revision?: any
  onClose?: any
}

interface FormProps {
  service_id: string
  date: string
  search: string
}

const useFetch = (clientId: number) => {
  const { t } = useTranslation();
  const initialForm = {
    id: clientId,
    date: '',
    service_id: '',
    page: 1,
    per_page: 20,
    last_page: 1,
    item_count: 0,
    revision: [],
    search: ''
  };

  const [data, setData] = useState<any[]>([]);
  const [canFetch, setCanFetch] = useState(true);
  const [pagination, setPagination] = useState(initialForm);

  const debounceTime = 500;
  let debounce: any = null;

  const fetchData = async () => {
    if (!canFetch) return;

    try {
      const res: any = await OperationService.posterGetTechs({
        status: [
          Constants.USER.STATUS.ACTIVE
        ],
        search: pagination.search,
        withoutLoading: true
      });

      if (!res?.data)
        return Globals.showError(t('common.globals.toast-error'));

      setCanFetch(false);

      setData(res.data);

    } catch (error) {
      console.log(error);
      Globals.showError(t('common.globals.toast-error'));
    }
  }

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setPagination(initialForm);
    fetchData();
  }

  const goToPage = (page: number) => {
    if (page >= 1 && page <= pagination.last_page && page !== pagination.page) {
      setCanFetch(true);
      setPagination({ ...pagination, page });
    }
  }

  const search = (target: keyof FormProps, value: string) => {
    if (debounce) clearTimeout(debounce);
    debounce = setTimeout(() => {
      setCanFetch(true);
      setPagination({ ...pagination, page: 1, [target]: value });
    }, debounceTime);
  }

  useEffect(() => {
    fetchData();
  }, [pagination]);

  return {
    operations: data,
    pagination,
    goToPage,
    reload,
    search,
  }
}

const TechListTools = ({ item, onClose }: Props) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<FormProps>({ service_id: '', date: '', search: '' });
  const { operations, pagination, goToPage, search } = useFetch(item.client_id);
  const [selectionItem, setSelectionItem] = useState<any>(null);
  const [data, setData] = useState<any[]>([]);

  const isValidForm = () => {
    const onError = (msg: string) => {
      Globals.showError(msg);
      return false;
    }

    if (!selectionItem)
      return onError(t('common.billboard.form.techs_list'));

    return true;
  }

  const submit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!isValidForm()) return;

    Socket.emit(socketEvents.OPERATIONS.OPERATION_NEW_TECH, {
      tech_id: selectionItem?.id,
      client_id: item?.client?.id,
      order_id: item?.id
    })
    onClose(true)
  }

  useEffect(() => {
    setData(operations);
  })

  return (
    <div id="admin-cs-modal-cs-details">
      <div className="content">
        <div className="wrapped">
          <div className="col col-12" style={{ marginBottom: '15px' }}>
            <Input
              filterStyle
              leftIcon={Search}
              noMargin
              value={formData.search}
              placeholder={t('common.billboard.tech_name')}
              onChange={v => {
                setFormData({ ...formData, search: v });
                search('search', v);
              }}
            />
          </div>
        </div>
        {data.length > 0 && (
          <>
            {data.map((item, index: number) => (
              <div className='wrapped' key={`list-tools-${index}`}>
                <button
                  onClick={() => {
                    item.isSelected = !item.isSelected;
                    setSelectionItem(item);
                    
                    const modifyData = data.map(x => {
                      if (x.id !== item.id) {
                        x.isSelected = false;
                      }
                      return x;
                    });

                    setData(modifyData)
                  }}
                  style={{ border: 'inherit', background: 'inherit' }}
                >
                  <Checkbox
                    label=''
                    style={{ marginTop: 20 }}
                    checked={item.isSelected}
                  />
                </button>
                <div className='col col-12' style={{ marginLeft: 20 }}>

                  <div className='wrapped'>
                    <div className="photo col col-2" style={{ borderRadius: 50, borderColor: 'black', borderWidth: 1 }}>
                      <img src={Globals.fromPhotos(item?.person?.photo)} alt="Foto de perfil" style={{ width: '60px' }} />
                    </div>
                    <div className="wrapped col col-12" style={{}}>
                      <div className='col-12'>{item?.name} {item?.lastname}</div>

                      <div className='col-12 wrapped' style={{ marginTop: 5 }}>
                        <div className="prop col col-4 wrapped">
                          <img src={PhoneIcon} alt="Foto de perfil" style={{ width: '20px', height: '20px' }} />
                          <div>{item?.person?.phone}</div>
                        </div>
                        <div className="prop col col-12 wrapped">
                          <img src={RecoveryEmail} alt="Foto de perfil" style={{ width: '20px', height: '20px' }} />
                          <div>{item?.email}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col col-12 wrapped' style={{ marginTop: 5 }}>
                    <div className="prop col col-12 wrapped">
                      <img src={LocationIcon} alt="Ubicacion" style={{ width: '20px', height: '20px' }} />
                      <div>{item?.person?.address}</div>
                    </div>
                  </div>
                </div>
                <div className="prop col col-12 divider" />
              </div>
            ))}
          </>
        )}


        <div className="wrapped" style={{ marginTop: 10 }}>
          <div className="actions" style={{ width: '100%', alignSelf: 'center' }}>
            <button className="btn--primary" style={{ width: '40%' }} onClick={submit}>
              <span>{t('common.globals.accept')}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TechListTools;