import { Api } from 'utils';
const TECH = 'techs/';

class TechService {

	static get = async (params = {}) => Api.createResponse('techs/get',params);
	static create = async (params = {}) => Api.createResponse('techs/create',params);
	static update = async (params = {}) => Api.createResponse('techs/update',params);
	static delete = async (params = {}) => Api.createResponse('techs/destroy',params);
	static changeStatus = async (params = {}) => Api.createResponse('techs/changestatus',params);
	static verified = async (params = {}) => Api.createResponse('techs/verified',params);
	static getAvailableTechs = async (params = {}) => Api.createResponse('techs/searchTechs',params);

	// Fetching tech
	static search_techs = async (params = {}) => Api.createResponse(`${TECH}searchTechs`,params);
	static getAverageRating = async (params = {}) => Api.createResponse('techs/getAverageRating',params);
}

export default TechService;