const setUser = (user: any) => ({
  type: 'SET_USER', payload: user,
})

const setLang = (lng: any) => ({
  type: 'SET_LANGUAGE', payload: lng,
})

const logout = () => ({
  type: 'LOGOUT'
})

export {
  setUser,
  setLang,
  logout,
}