import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Input } from 'components';
import { AuthService } from 'services';
import { Globals } from 'utils';
import { ArrowLeft, RecoveryPassword } from 'assets/icons';

const RecoverPassword = () => {

  const history = useHistory();
  const { code } = useParams<{ code: string }>();
  const [formData, setFormData] = useState({
    code,
    password: '',
    password_confirmation: '',
  });

  const onChange = (target: keyof typeof formData, value: string) => {
    setFormData(s => ({ ...s, [target]: value.toLowerCase().toUpperCase() }))
  }

  const isValidForm = (): boolean => {
    const onError = (msg: string) => {
      Globals.showError(msg);
      return false;
    }

    if (!formData.code)
      return onError('El código es obligatorio');

    if (!formData.password)
      return onError('La contraseña es obligatoria');

    if (!formData.password_confirmation)
      return onError('Debe confirmar la contraseña');

    if (formData.password !== formData.password_confirmation)
      return onError('Las contraseñas no coinciden');

    return true;
  }

  const submit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!isValidForm()) return;

    try {
      const res: any = await AuthService.recoverPassword(formData);

      if (res?.error) return Globals.showError(res.error);
      if (!res?.result) return Globals.showError('Código inválido');

      Globals.showSuccess('Contraseña actualizada con éxito');
      history.replace('/login');

    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div id="recover">
      <div className="form-wrapper">
        <button type="button" className="go-back" onClick={() => history.goBack()}>
          <img src={ArrowLeft} alt="Volver" />
        </button>

        <div className="icon">
          <img src={RecoveryPassword} alt="Icono" />
        </div>

        <form onSubmit={submit}>
          <div>
            <Input
              placeholder="Nueva contraseña"
              type="password"
              value={formData.password}
              onChange={v => onChange('password', v)}
            />

            <Input
              placeholder="Repetir contraseña"
              type="password"
              value={formData.password_confirmation}
              onChange={v => onChange('password_confirmation', v)}
              noMargin
            />
          </div>

          <div className="submit">
            <button className="btn--primary" type="submit">
              <span>Guardar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default RecoverPassword;