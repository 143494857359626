/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Input, SelectSearch } from 'components';
import { Trash } from 'assets/icons';
import { Globals } from 'utils';
import { ServiceService } from 'services';
import { IList } from 'types';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

interface ServiceDetailsProps {
  service?: any,
  equipments: any[]
  onClose: (reloading: boolean) => void
}

interface IForm {
  equipment: IList | undefined
  equipment_id: number | undefined
  cost_service: string
  cost_labor: string
  commission: string
  equipments: any[]
}

const initialForm: IForm = {
  equipment: undefined,
  equipment_id: undefined,
  cost_service: '',
  cost_labor: '',
  commission: '',
  equipments: [],
};

const ServiceDetails = ({ service, equipments, onClose }: ServiceDetailsProps) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(initialForm);

  const onChange = (target: keyof typeof formData, value: string) => {
    setFormData((s: any) => ({ ...s, [target]: value }))
  }

  const addEquipment = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!isValidForm()) return;

    const newEquipment = {
      service_id: service.id,
      equipment_id: formData.equipment_id,
      equipment: {
        id: formData.equipment?.value,
        name: formData.equipment?.label,
      },
      cost_service: formData.cost_service,
      cost_labor: formData.cost_labor,
      commission: formData.commission,
    };

    setFormData({
      ...initialForm,
      equipments: [
        ...formData.equipments,
        newEquipment,
      ],
    });
  }

  const deleteEquipment = (index: number) => {
    const others = formData.equipments.filter((_, idx) => idx !== index);
    setFormData(s => ({ ...s, equipments: others }));
  }

  const submit = async () => {
    if (!service.equipments_services.length && !formData.equipments.length)
      return Globals.showError(t('common.service.form.add_equip'));

    const data = {
      id: service.id,
      name: service.name,
      equipments: formData.equipments,
    };

    try {
      const res: any = await ServiceService.update(data);
      if (!res?.service) return Globals.showError();

      Globals.showSuccess(t('common.globals.added_equipment'));
      onClose(true);

    } catch (error) {
      console.log(error);
      Globals.showError();
    }
  }

  const isValidForm = (): boolean => {
    const onError = (msg: string) => {
      Globals.showError(msg);
      return false;
    }

    if (!formData.equipment_id)
      return onError(t('common.service.form.name'));

    if (!formData.cost_service)
      return onError(t('common.service.form.service_cost'));

    if (!formData.cost_labor)
      return onError(t('common.service.form.labor_cost'));

    if (!formData.commission)
      return onError(t('common.service.form.commission'));

    return true;
  }

  useEffect(() => {
    setFormData(s => ({
      ...s,
      equipments: service.equipments_services || [],
    }));
  }, []);

  return (
    <div id="admin-services-modal-service-details" className="content">
      <form onSubmit={addEquipment}>
        <div className="form--group">
          <div style={{ flex: 5 }}>
            <SelectSearch
              options={equipments.map(x => ({ value: x.id, label: x.name }))}
              label={t('common.service.equipment') || ''}
              value={formData.equipment}
              onChange={v => {
                onChange('equipment', v);
                onChange('equipment_id', v.value);
              }}
            />
          </div>
          <div style={{ flex: 2 }}>
            <Input
              className="text-right"
              type="number"
              label={t('common.service.basic_cost') || ''}
              value={formData.cost_service}
              onChange={v => onChange('cost_service', v)}
              preppend={<span>$</span>}
            />
          </div>
          <div style={{ flex: 2 }}>
            <Input
              className="text-right"
              type="number"
              label={t('common.service.commission') || ''}
              value={formData.commission}
              onChange={v => onChange('commission', v)}
              preppend={<span>%</span>}
            />
          </div>
          <div style={{ flex: 2 }}>
            <Input
              className="text-right"
              type="number"
              label={t('common.service.service_cost') || ''}
              value={formData.cost_labor}
              onChange={v => onChange('cost_labor', v)}
              preppend={<span>$</span>}
            />

          </div>
        </div>

        <button className="form--submit-button" type="submit" style={{ marginBottom: '1rem' }}>
          <span>{t('common.globals.add') || ''}</span>
        </button>
      </form>

      <div className="divider" />

      {!!formData.equipments.length ? (
        <div style={{ maxHeight: 200, overflowY: 'auto' }}>
          <div className="wrapped">
            <table>
              <thead>
                <tr>
                  <th>{t('common.service.name')}</th>
                  <th>{t('common.service.basic_cost')}</th>
                  <th>{t('common.service.commission')}</th>
                  <th>{t('common.service.service_cost')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {formData.equipments.map((x: any, index: number) => (
                  <tr key={`equipment-${index}`}>
                    <td>{x?.equipment?.name}</td>
                    <td>{x.cost_service}</td>
                    <td>{x.commission}</td>
                    <td>{x.cost_labor}</td>
                    <td className="row-actions">
                      <img
                        className="action-icon"
                        src={Trash}
                        alt=""
                        onClick={() => deleteEquipment(index)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="flex-center">
          <div className="no-items">{t('common.service.not_data_found')}</div>
        </div>
      )}

      <div className="flex-center actions">
        <button className="form--submit-button" type="button" onClick={submit}>
          <span>{t('common.globals.save')}</span>
        </button>
      </div>
    </div>
  )
}

export default ServiceDetails;