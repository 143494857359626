/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Input, Select, DatePicker } from 'components';
import { OperationService } from 'services';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Globals } from 'utils';

interface Props {
  item?: any
  onClose: (reloading: boolean) => void
}

interface FormProps {
  date: string
  hour: string
}

const useFetch = (clientId: number) => {
  const { t } = useTranslation();
  const initialForm = {
    id: clientId,
    date: '',
    service_id: '',
    page: 1,
    per_page: 20,
    last_page: 1,
    item_count: 0,
    revision: [],
  };

  const [data, setData] = useState<any[]>([]);
  const [canFetch, setCanFetch] = useState(true);
  const [pagination, setPagination] = useState(initialForm);

  const fetchData = async () => {
    if (!canFetch) return;

  }

  useEffect(() => {
    fetchData();
  }, [pagination]);

  return {

  }
}

const OrderDetail = ({ item, onClose }: Props) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<FormProps>({ date: '', hour: '' });
  const { } = useFetch(item.client_id);


  const onChange = (target: keyof typeof formData, value: string) => {
    setFormData(s => ({ ...s, [target]: value }));
  }

  const submit = async () => {
    const date = `${formData.date} ${formData.hour}:00`;
    const data = {
      date,
      order_id: item.id
    };
    const res: any = await OperationService.posterEditOrderDate(data);
    if (res.data) {
      Globals.showSuccess(t('common.globals.edited_successfully'))
      onClose(true)
    }
  }

  useEffect(() => {
    setFormData({
      date: moment(item.date).format('YYYY-MM-DD'),
      hour: moment(item.date).format('hh:mm')
    })
  }, []);

  return (
    <div id="admin-cs-modal-cs-details">
      <div className='content'>
        <div className="wrapped">
          <div className="prop col col-5" style={{ marginRight: '1rem' }}>
            <div className="label">{t('common.globals.date')}</div>
            <Input
              type="date"
              value={formData.date}
              onChange={v => onChange('date', v)}
            />
          </div>
          <div className="prop col col-6">
            <Input
              type="time"
              label={t('common.globals.hour') || ''}
              value={formData.hour}
              onChange={v => onChange('hour', v)}
            />
          </div>
        </div>
        <div className="wrapped" style={{ marginTop: 10 }}>
          <div className="actions" style={{ width: '100%', alignSelf: 'center' }}>
            <button className="btn--primary" style={{ width: '40%' }} onClick={submit}>
              <span>{t('common.globals.save')}</span>
            </button>
          </div>
        </div>
      </div>
      {/*<div className="content">
        <div className="wrapped">
          <div className="prop col col-6">
            <img src={Globals.fromPhotos(item?.client?.person.photo)} alt="Foto de perfil" />
            <div>{item?.client?.name} {item?.client?.lastname}</div>
          </div>

          <div className="prop col col-12 divider" />

          <div style={{ background: Colors.blue, width: '100%', padding: 10 }}>
            <div className="prop col col-12">
              <div className="label" style={{ color: 'white', fontWeight: 'bold' }}>{t('common.operations.schedule.schedule_title').toUpperCase()}</div>
            </div>
            <div className="prop col col-12">
              <div style={{ color: 'white' }}>
                <img
                  className="action-icon cursor-pointer"
                  src={CalendarService}
                  alt=""
                  style={{ width: '25px', marginRight: 10, marginBottom: 5 }}
                />
                {moment(item.date || item.created_at).format('DD-MM-YYYY - HH:mm a')}
              </div>
            </div>
          </div>
        </div>
        <div className="wrapped" style={{ marginTop: 10 }}>
          <div className="prop col col-1">
            <img src={PhoneIcon} alt="Foto de perfil" style={{ width: '25px' }} />
          </div>
          <div className="prop col col-11" style={{ marginTop: 3 }}>
            <div><span style={{ fontWeight: 'bold' }}>{t('common.operations.phone')}: </span>{item?.client?.person?.phone}</div>
          </div>
          <div className="prop col col-1">
            <img src={RecoveryEmail} alt="Foto de perfil" style={{ width: '25px' }} />
          </div>
          <div className="prop col col-11" style={{ marginTop: 3 }}>
            <div><span style={{ fontWeight: 'bold' }}>{t('common.operations.email')}: </span>{item?.client?.email}</div>
          </div>
          <div className="prop col col-1">
            <img src={LocationIcon} alt="Foto de perfil" style={{ width: '25px' }} />
          </div>
          <div className="prop col col-11" style={{ marginTop: 3 }}>
            <div>{item?.client?.person?.address}</div>
          </div>
        </div>
        <div className="wrapped" style={{ marginTop: 10 }}>
          <div className="prop col col-12">
            <div className="label" style={{ color: Colors.blue, fontWeight: 'bold' }}>{t('common.globals.equipment')}</div>
          </div>
          <div className="prop col col-12">
            <div className="label" style={{ fontWeight: 'bold' }}>{ item?.equipment?.equipment?.name }</div>
          </div>
        </div>
      </div>*/}
    </div>
  )
}

export default OrderDetail;