/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { UserService } from 'services';
import { IUser } from 'types';
import { Globals } from 'utils';
import { Add, Edit, Search, Trash } from '../../../assets/icons';
import { Modal, Pagination } from '../../../components'
import CreateUser from './CreateUser';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const useFetch = () => {
  const { t } = useTranslation();
  const initialForm = {
    search: '',
    page: 1,
    per_page: 20,
    last_page: 1,
    item_count: 0,
  };

  const [data, setData] = useState<IUser[]>([]);
  const [canFetch, setCanFetch] = useState(true);
  const [pagination, setPagination] = useState(initialForm);

  const debounceTime = 500;
  let debounce: any = null;

  const fetchData = async () => {
    if (!canFetch) return;

    try {
      const res: any = await UserService.get({
        page: pagination.page,
        per_page: pagination.per_page,
        search: pagination.search,
      });

      if (!res?.user?.rows)
        return Globals.showError(t('common.globals.toast-error'));

      const last_page = Math.ceil((res?.user?.count || 0) / pagination.per_page);
      setCanFetch(false);
      setPagination({
        ...pagination,
        item_count: res?.user?.count,
        last_page,
      });
      setData(res.user.rows);

    } catch (error) {
      console.log(error);
      Globals.showError(t('common.globals.toast-error'));
    }
  }

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setPagination(initialForm);
    fetchData();
  }

  const prevPage = () => {
    if (pagination.page > 1) {
      setCanFetch(true);
      setPagination({ ...pagination, page: pagination.page + 1 });
    }
  }

  const nextPage = () => {
    if (pagination.page < pagination.last_page) {
      setCanFetch(true);
      setPagination({ ...pagination, page: pagination.page + 1 });
    }
  }

  const goToPage = (page: number) => {
    if (page >= 1 && page <= pagination.last_page && page !== pagination.page) {
      setCanFetch(true);
      setPagination({ ...pagination, page });
    }
  }

  const search = (search: string) => {
    if (debounce) clearTimeout(debounce);
    debounce = setTimeout(() => {
      setCanFetch(true);
      setPagination({ ...pagination, page: 1, search });
    }, debounceTime);
  }

  useEffect(() => {
    fetchData();
  }, [pagination]);

  return {
    users: data,
    pagination,
    nextPage,
    prevPage,
    goToPage,
    reload,
    search,
  }
}

const Users = () => {
  const { t } = useTranslation();

  const [modal, setModal] = useState(false);
  const { users, pagination, reload, goToPage, search } = useFetch();
  const [selectedUser, setSelectedUser] = useState<IUser>();

  const editUser = (user: IUser) => {
    setSelectedUser(user);
    setModal(true);
  }

  const deleteUser = (user: IUser) => {
    const doIt = async () => {
      try {
        const res: any = await UserService.delete({ id: user.id });
        if (!res) return Globals.showError();

        Globals.showSuccess(t('common.globals.deleted-user'));
        reload();

      } catch (error) {
        console.log(error);
        Globals.showError();
      }
    }

    Globals.confirm(t('common.globals.deleted-user-confirm'), doIt);
  }

  const toogleStatus = async (user: IUser) => {
    try {
      const res: any = await UserService.changeStatus({ id: user.id });
      if (!res) return Globals.showError();

      Globals.showSuccess(t('common.globals.deleted-user'));
      reload();

    } catch (error) {
      console.log(error);
      Globals.showError();
    }
  }

  const onCloseModal = (reloading: boolean) => {
    console.log({reload: reloading})
    if (reloading) reload();
    if (selectedUser) setSelectedUser(undefined);
    setModal(false);
  }

  return (
    <>
      <Modal title={`${!!selectedUser ? t('common.globals.edit'):t('common.globals.add')} ${t('common.globals.user')}`} visible={modal} onClose={() => onCloseModal(false)}>
        <CreateUser user={selectedUser} onClose={onCloseModal} />
      </Modal>

      <div style={{ marginBottom: 40 }}>
        <div>
          <span className="input-label">{t('common.globals.search')}</span>
          <div className="search-filter" style={{ width: '40%' }}>
            <img src={Search} alt=""/>
            <input
              placeholder={t('common.globals.search_input') || ''}
              onChange={v => search(v.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="custom-table">
        <div className="header">
          <span className="title">{t('common.users.registered_users')}</span>
          <div className="actions">
            <button className="btn--primary" onClick={() => setModal(true)}>
              <img src={Add} width={24} alt="" style={{ marginRight: 10 }} />
              <span>{t('common.users.new_user')}</span>
            </button>
          </div>
        </div>

        {!!users.length ? (
          <table>
            <thead>
              <tr>
                <th>{t('common.users.name')}</th>
                <th>{t('common.users.email')}</th>
                <th>{t('common.users.user')}</th>
                <th>{t('common.users.profile')}</th>
                <th className="text-right">{t('common.users.actions')}</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={`row-${index}`}>
                  <td>{ user.name }</td>
                  <td>{ user.email }</td>
                  <td>{ user.username }</td>
                  <td>{ user.level?.name }</td>
                  <td className="row-actions right">
                    <div className="cursor-pointer" onClick={() => toogleStatus(user)}>
                      {user.status ? (
                        <span className="badge badge--success">{t('common.users.actions_messages.active')}</span>
                      ) : (
                        <span className="badge badge--danger">{t('common.users.actions_messages.unable')}</span>
                      )}
                    </div>
                    <img
                      className="action-icon"
                      src={Trash}
                      alt=""
                      onClick={() => deleteUser(user)}
                    />
                    <img
                      className="action-icon"
                      src={Edit}
                      alt=""
                      onClick={() => editUser(user)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="no-items">{t('common.users.no_users_found')}</div>
        )}
      </div>

      {!!users.length && (
        <Pagination
          active={pagination.page}
          pages={pagination.last_page}
          onChange={goToPage}
        />
      )}
    </>
  )
}

export default Users;