/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Input, Pagination, Select } from 'components';
import { OperationService, ServiceService } from 'services';
import { Constants, Globals } from 'utils';
import { IList, IServiceType } from 'types';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const getPropertyType = (type: number) => {
  switch (type) {
    case Constants.PROPERTY.TYPE.DEPARTMENT.ID:
      return Constants.PROPERTY.TYPE.DEPARTMENT.LABEL;

    case Constants.PROPERTY.TYPE.HOUSE.ID:
      return Constants.PROPERTY.TYPE.DEPARTMENT.LABEL;

    case Constants.PROPERTY.TYPE.LOCAL.ID:
      return Constants.PROPERTY.TYPE.LOCAL.LABEL;

    default:
      return 'No definido';
  }
}

interface Props {
  item?: any
  revision?: any
  onClose?: (reloading: boolean) => void
}

interface FormProps {
  service_id: string
  date: string
}

const useFetch = (clientId: number) => {
  const { t } = useTranslation();
  const initialForm = {
    id: clientId,
    date: '',
    service_id: '',
    page: 1,
    per_page: 20,
    last_page: 1,
    item_count: 0,
    revision: [],
  };

  const [data, setData] = useState<any[]>([]);
  const [canFetch, setCanFetch] = useState(true);
  const [pagination, setPagination] = useState(initialForm);

  const debounceTime = 500;
  let debounce: any = null;

  const fetchData = async () => {
    if (!canFetch) return;

    try {
      const res: any = await OperationService.getByClient({
        page: pagination.page,
        per_page: pagination.per_page,
        id: clientId,
        date: pagination.date,
        service_id: pagination.service_id,
      });

      if (!res?.data?.rows)
        return Globals.showError(t('common.globals.toast-error'));

      for await (const item of res?.data?.rows) {
        item.cost = (await serviceView(item))?.data?.cost_labor || item.cost;
      }

      const last_page = res?.data?.count/*Math.ceil((res?.data?.count || 0) / pagination.per_page)*/;
      setCanFetch(false);
      setPagination({
        ...pagination,
        item_count: res?.data?.count,
        last_page,
      });
      setData(res.data.rows);

    } catch (error) {
      console.log(error);
      Globals.showError(t('common.globals.toast-error'));
    }
  }

  const serviceView = async (item: any) => {
    if (item?.service_id) {
      const equipment_service: any = await ServiceService.equipmentServiceView({
        equipment_id: item?.equipment?.equipment?.id,
        service_id: item.service?.id,
        withoutLoading: true
      });

      return equipment_service;
    }
  }

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setPagination(initialForm);
    fetchData();
  }

  const prevPage = () => {
    if (pagination.page > 1) {
      setCanFetch(true);
      setPagination({ ...pagination, page: pagination.page + 1 });
    }
  }

  const nextPage = () => {
    if (pagination.page < pagination.last_page) {
      setCanFetch(true);
      setPagination({ ...pagination, page: pagination.page + 1 });
    }
  }

  const goToPage = (page: number) => {
    if (page >= 1 && page <= pagination.last_page && page !== pagination.page) {
      setCanFetch(true);
      setPagination({ ...pagination, page });
    }
  }

  const search = (target: keyof FormProps, value: string) => {
    if (debounce) clearTimeout(debounce);
    debounce = setTimeout(() => {
      setCanFetch(true);
      setPagination({ ...pagination, page: 1, [target]: value });
    }, debounceTime);
  }

  useEffect(() => {
    fetchData();
  }, [pagination]);

  return {
    operations: data,
    pagination,
    nextPage,
    prevPage,
    goToPage,
    reload,
    search,
  }
}

const CSDetails = ({ item, revision }: Props) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<FormProps>({ service_id: '', date: '' });
  const [services, setServices] = useState<IList[]>([]);
  const { operations, pagination, goToPage, search } = useFetch(item.client_id);

  const onChange = (target: keyof typeof formData, value: string) => {
    setFormData(s => ({ ...s, [target]: value }));
    search(target, value);
  }

  const searchServices = async () => {
    try {
      const res: any = await ServiceService.get({ per_page: 20, page: 1 });
      if (!res?.data || res?.error) return Globals.showError();

      const options: IList[] = res.data.services.rows.map((x: IServiceType) =>
        ({ value: String(x.id), label: x.name })
      );

      setServices([
        { value: '', label: t('common.globals.all') },
        { value: '9', label: t('common.globals.revision') },
        ...options,
      ]);

    } catch (error) {
      console.log(error)
      Globals.showError();
    }
  }

  const getCost = (item: any) => {
    const cost = parseFloat(item?.cost || 0);
    let partsCost = 0;
    if (!!item?.details?.length) {
      partsCost = item.details
        .reduce((prev: number, curr: any) => prev + (parseFloat(curr.cost) * curr.qty), 0);
    }
    return parseFloat((cost + partsCost).toFixed(2));
  }

  const getCostFromService = (item: any) => {
    const equipment_service = item.equipment?.equipment?.services;
    const filter = equipment_service?.filter(function (i: any) {
      return i.service_id == item.service_id;
    }) || [];
    const value = filter[0]?.cost_service || 0;
    return parseFloat(value).toFixed(2);
  }

  const getCommissionFromService = (item: any) => {
    const equipment_service = item.equipment?.equipment?.services
    const filter = equipment_service?.filter(function (i: any) {
      return i.service_id == item.service_id;
    }) || [];
    
    const value = filter[0]?.commission || 0;
    if(value > 0){
      return ((Number(value) * filter[0].cost_service) / 100).toFixed(2)
    }
    else{
      return Number(value).toFixed(2)
    }
    
  }

  useEffect(() => {
    searchServices();
  }, []);

  if (!item) return (
    <div className="flex-center">
      <h5>{t('common.operations.commissions.form.select_service')}</h5>
    </div>
  )

  return (
    <div id="admin-cs-modal-cs-details">
      <div className="content">
        <div className="wrapped">
          <div className="prop col col-4">
            <div className="label">{t('common.operations.phone')}</div>
            <div>{item?.client?.person?.phone}</div>
          </div>
          <div className="prop col col-4">
            <div className="label">{t('common.operations.email')}</div>
            <div>{item?.client?.email}</div>
          </div>
          <div className="prop col col-4">
            <div className="label">{t('common.globals.client_type')}</div>
            <div>{item?.client?.level?.name}</div>
          </div>
          <div className="prop col col-12">
            <div className="label">{t('common.globals.location')}</div>
            <div>{item?.property?.address}</div>
          </div>

          <div className="prop col col-12 divider" />

          <div className="prop col col-4">
            <div className="label">{t('common.globals.service')}</div>
            <Select
              name="serviceId"
              options={services}
              value={formData.service_id}
              onChange={v => onChange('service_id', v)}
            />
          </div>
          <div className="prop col col-4" style={{ marginLeft: '1rem' }}>
            <Input
              label={t('common.globals.date') || ''}
              type="date"
              filterStyle
              onChange={v => onChange('date', v)}
            />
          </div>
        </div>

        {!!operations.length ? (
          <div className="wrapped">
            <table>
              <thead>
                <tr>
                  <th>{t('common.operations.service_number')}</th>
                  <th>{t('common.globals.date')}</th>
                  <th>{t('common.globals.tech')}</th>
                  <th>{t('common.globals.property.property_data')}</th>
                  <th>{t('common.globals.equipment')}</th>
                  <th>{t('common.globals.service_type')}</th>
                  <th>{t('common.globals.spare')}</th>
                  <th>{t('common.globals.cost')}</th>
                  <th>{t('common.globals.commission')} Below</th>
                </tr>
              </thead>
              <tbody>
                {operations.map(x => (
                  <tr key={`row-${x.id}`}>
                    <td>{String(x?.code || x?.id).padStart(6, '0')}</td>
                    <td>{moment(x?.created_at).format('DD/MM/YYYY')}</td>
                    <td>{x?.tech?.name} {x.tech?.lastname}</td>
                    <td>{getPropertyType(x.property?.type ?? 0)}</td>
                    <td>{x?.equipment?.equipment?.name ?? ''}</td>
                    <td>{x?.service?.name ?? 'Revisión'}</td>
                    {/* <td>{getCost(x)}</td>
                    <td>{
                      x?.service !== null ? (
                        (Number(x?.commission) * x.cost) / 100
                      ) : (
                        x?.commission
                      )
                    }</td> */}
                    <td className="spare-parts">
                      <span>
                      {
                        x?.details?.map((item: any) => (
                          <span>
                            <span>{t('common.globals.name')}: {item?.spare?.name}, </span>
                            <span>{t('common.globals.amount')}: {item?.qty}, </span>
                            <span>{t('common.globals.cost')}: {item?.cost},</span>
                            <span>Total: {Number(item?.qty * item?.cost).toFixed(2)}</span>
                          </span>
                        ))
                      }
                      </span>
                      <div>
                      {
                        x?.details?.map((item: any) => {
                          return (
                            <div>
                              <span>{t('common.globals.name')}: {item?.spare?.name}, </span>
                              <span>{t('common.globals.amount')}: {item?.qty}, </span>
                              <span>{t('common.globals.cost')}: {item?.cost},</span>
                              <span>Total: {Number(item?.qty * item?.cost).toFixed(2)}</span>
                            </div>
                          )
                        })
                      }
                      </div>
                    </td>
                    <td>
                      {
                        x.service !== null ?
                          getCostFromService(x)
                        :
                          //getCost(i)
                          //(revision?.amount).toFixed(2)
                          (Number(revision?.amount) - ( Number(revision.tech_percentage) * Number(revision.amount) / 100 )).toFixed(2)
                      }
                    </td>
                    <td>
                      {x?.service !== null ? (
                        getCommissionFromService(x)
                      ) : (
                        //i?.commission
                        ( Number(revision.tech_percentage) * Number(revision.amount) / 100 ).toFixed(2)
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="no-items">{t('common.operations.commissions.not_data_found')}</div>
        )}

        {!!operations.length && (
          <div className="wrapped">
            <Pagination
              active={pagination.page}
              pages={pagination.last_page}
              onChange={goToPage}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default CSDetails;