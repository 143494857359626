import { Api } from 'utils';

class OperationService {

	static get = async (params = {}) => Api.createResponse('operations/get',params);
	static getByClient = async (params = {}) => Api.createResponse('operations/get-byclient',params);
	static getComissionsAndServices = async (params = {}) => Api.createResponse('operations/get-commissions-services',params);
	static historyServicePDF = async (params = {}) => Api.createResponse('operations/quotation/history-service/pdf',params);

	static getPoster = async (params = {}) => Api.createResponse('operations/get-poster',params);
	static posterGetTechs = async (params = {}) => Api.createResponse('operations/poster/get-techs',params);
	static posterSaveNotification = async (params = {}) => Api.createResponse('operations/poster/save-notification',params);
	static posterEditOrderDate = async (params = {}) => Api.createResponse('operations/poster/editOrderDate',params);
}

export default OperationService;