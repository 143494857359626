/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { CustomerService } from 'services';
import { ICustomer } from 'types';
import { Globals } from 'utils';
import { Search, Trash, ViewBlue } from '../../../assets/icons';
import { Modal, Pagination } from '../../../components'
import UserDetails from './UserDetails';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const useFetch = () => {
  const { t } = useTranslation();
  const initialForm = {
    search: '',
    page: 1,
    per_page: 20,
    last_page: 1,
    item_count: 0,
  };

  const [data, setData] = useState<ICustomer[]>([]);
  const [canFetch, setCanFetch] = useState(true);
  const [pagination, setPagination] = useState(initialForm);

  const debounceTime = 500;
  let debounce: any = null;

  const fetchData = async () => {
    if (!canFetch) return;

    try {
      const res: any = await CustomerService.get({
        page: pagination.page,
        per_page: pagination.per_page,
        search: pagination.search,
      });

      if (!res?.data?.rows)
        return Globals.showError(t('common.globals.toast-error'));

      const last_page = Math.ceil((res?.data?.count || 0) / pagination.per_page);
      setCanFetch(false);
      setPagination({
        ...pagination,
        item_count: res?.data?.count,
        last_page,
      });
      setData(res.data.rows);

    } catch (error) {
      console.log(error);
      Globals.showError(t('common.globals.toast-error'));
    }
  }

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setPagination(initialForm);
    fetchData();
  }

  const prevPage = () => {
    if (pagination.page > 1) {
      setCanFetch(true);
      setPagination({ ...pagination, page: pagination.page + 1 });
    }
  }

  const nextPage = () => {
    if (pagination.page < pagination.last_page) {
      setCanFetch(true);
      setPagination({ ...pagination, page: pagination.page + 1 });
    }
  }

  const goToPage = (page: number) => {
    if (page >= 1 && page <= pagination.last_page && page !== pagination.page) {
      setCanFetch(true);
      setPagination({ ...pagination, page });
    }
  }

  const search = (search: string) => {
    if (debounce) clearTimeout(debounce);
    debounce = setTimeout(() => {
      setCanFetch(true);
      setPagination({ ...pagination, page: 1, search });
    }, debounceTime);
  }

  useEffect(() => {
    fetchData();
  }, [pagination]);

  return {
    customers: data,
    pagination,
    nextPage,
    prevPage,
    goToPage,
    reload,
    search,
  }
}

const Customers = () => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const { customers, pagination, reload, goToPage, search } = useFetch();
  const [selectedUser, setSelectedUser] = useState<ICustomer>();

  const viewUser = (user: ICustomer) => {
    setSelectedUser(user);
    setModal(true);
  }

  const deleteUser = (user: ICustomer) => {
    const doIt = async () => {
      try {
        const res: any = await CustomerService.delete({ id: user.id });
        if (!res) return Globals.showError();

        Globals.showSuccess(t('common.globals.client_deleted'));
        reload();

      } catch (error) {
        console.log(error);
        Globals.showError();
      }
    }

    Globals.confirm(t('common.globals.client-tech-confirm'), doIt);
  }

  const onCloseModal = (reloading: boolean) => {
    if (reloading) reload();
    setModal(false);
  }

  return (
    <div id="admin-customers">
      <Modal
        title={`${selectedUser?.name || ''} ${selectedUser?.lastname || ''}`}
        titleClassName="text-blue"
        size="lg"
        visible={modal}
        onClose={() => onCloseModal(false)}
      >
        <UserDetails user={selectedUser} onClose={onCloseModal} />
      </Modal>

      <div className="search-bar">
        <div>
          <span className="input-label">{t('common.globals.search')}</span>
          <div className="search-filter" style={{ width: '40%' }}>
            <img src={Search} alt=""/>
            <input
              placeholder={t('common.globals.search') || ''}
              onChange={v => search(v.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="custom-table">
        <div className="header">
          <span className="title">{t('common.clients.clients-registered')}</span>
        </div>

        {!!customers.length ? (
          <table>
            <thead>
              <tr>
                <th>{t('common.clients.name')}</th>
                <th>{t('common.clients.phone')}</th>
                <th>{t('common.clients.email')}</th>
                <th>{t('common.clients.type')}</th>
                <th>{t('common.clients.amount_properties_registered')}</th>
                <th>{t('common.clients.actions')}</th>
              </tr>
            </thead>
            <tbody>
              {customers.map((user, index) => (
                <tr key={`row-${index}`}>
                  <td>
                    <span className="text-blue cursor-pointer" onClick={() => viewUser(user)}>
                      { user.name } { user.lastname}
                    </span>
                  </td>
                  <td>{ user?.person?.phone }</td>
                  <td className="text-break" style={{ minWidth: '170px' }}>{ user.email }</td>
                  <td>{ t(Globals.getLevelName(user.level.id)) }</td>
                  <td>{ user.properties?.length || 0 }</td>
                  <td className="row-actions">
                    <img
                      className="action-icon"
                      src={Trash}
                      alt=""
                      onClick={() => deleteUser(user)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="no-items">{t('common.clients.not_works_found')}</div>
        )}
      </div>

      {!!customers.length && (
        <Pagination
          active={pagination.page}
          pages={pagination.last_page}
          onChange={goToPage}
        />
      )}
    </div>
  )
}

export default Customers;