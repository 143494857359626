import { Api } from 'utils';

class UserService {

	static get = async (params = {}) => Api.createResponse('users/get',params);
	static create = async (params = {}) => Api.createResponse('users/create',params);
	static update = async (params = {}) => Api.createResponse('users/update',params);
	static delete = async (params = {}) => Api.createResponse('users/delete',params);
	static changeStatus = async (params = {}) => Api.createResponse('users/changestatus',params);

}

export default UserService;