import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import es from './es.json';

// i18n.use(initReactI18next).init({
//   compatibilityJSON: 'v3',
//   lng: 'en',
//   fallbackLng: 'en',
//   resources: {
//     en: {
//       translation: {
//         common: en
//       }
//     },
//     es: {
//       translation: {
//         common: es
//       }
//     }
//   },
//   interpolation: {
//     escapeValue: false
//   }
// });

function capitalizeAllWords(obj) {
  for (var key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = obj[key].toUpperCase();
    } else {
      capitalizeAllWords(obj[key]);
    }
  }
}

const resources = {
  en: {
    translation: {
      common: en
    }
  },
  es: {
    translation: {
      common: es
    }
  }
};

capitalizeAllWords(resources.en.translation.common);
capitalizeAllWords(resources.es.translation.common);

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  lng: 'en',
  fallbackLng: 'en',
  resources: resources,
  interpolation: {
    escapeValue: false
  }
});
  
export default i18n;