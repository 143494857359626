/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Input, Textarea, PaperClip } from 'components';
import { OperationService, ServiceService } from 'services';
import { Constants, Globals, Colors, Socket, socketEvents } from 'utils';
import { IList, IServiceType } from 'types';
import {

} from 'assets/icons';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

interface Props {
    item?: any
    onClose?: any
}

interface FormProps {
    service_id: string
    date: string
    message: string
    files: any
}

const useFetch = () => {
    const { t } = useTranslation();
    const initialForm = {
        date: '',
        service_id: '',
        page: 1,
        per_page: 20,
        last_page: 1,
        item_count: 0,
        revision: [],
    };

    const [data, setData] = useState<any[]>([]);
    const [canFetch, setCanFetch] = useState(true);
    const [pagination, setPagination] = useState(initialForm);

    const debounceTime = 500;
    let debounce: any = null;

    const fetchData = async () => {
        if (!canFetch) return;

        try {
            const res: any = await OperationService.posterGetTechs({
                status: [
                    Constants.USER.STATUS.ACTIVE
                ]
            });

            if (!res?.data)
                return Globals.showError(t('common.globals.toast-error'));

            setCanFetch(false);

            setData(res.data);

        } catch (error) {
            console.log(error);
            Globals.showError(t('common.globals.toast-error'));
        }
    }

    const reload = (inSamePage = false) => {
        setCanFetch(true);
        if (!inSamePage) setPagination(initialForm);
        fetchData();
    }

    const goToPage = (page: number) => {
        if (page >= 1 && page <= pagination.last_page && page !== pagination.page) {
            setCanFetch(true);
            setPagination({ ...pagination, page });
        }
    }

    const search = (target: keyof FormProps, value: string) => {
        if (debounce) clearTimeout(debounce);
        debounce = setTimeout(() => {
            setCanFetch(true);
            setPagination({ ...pagination, page: 1, [target]: value });
        }, debounceTime);
    }

    useEffect(() => {
        fetchData();
    }, [pagination]);

    return {
        operations: data,
        pagination,
        goToPage,
        reload,
        search,
    }
}

const NextStepTechList = ({ item, onClose }: Props) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState<FormProps>({ service_id: '', date: '', message: '', files: null });

    const searchServices = async () => {
        try {
            const res: any = await ServiceService.get({ per_page: 20, page: 1 });
            if (!res?.data || res?.error) return Globals.showError();

        } catch (error) {
            console.log(error)
            Globals.showError();
        }
    }

    const isValidForm = () => {
        const onError = (msg: string) => {
            Globals.showError(msg);
            return false;
        }

        if (!formData.message)
            return onError(t('common.billboard.form.message'));

        return true;
    }

    const submit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!isValidForm()) return;

        const notification = {
            techs: JSON.stringify(item),
            message: formData.message,
            files: formData.files?.value,
            hasFile: true
        }
        const res: any = await OperationService.posterSaveNotification(notification);
        if (res.data) {
            Globals.showSuccess(t('common.billboard.notif_successfully'))
            onClose(true)
        }
    }

    useEffect(() => {
        searchServices();
    }, []);

    return (
        <div id="admin-cs-modal-cs-details">
            <div className="content">
                <div className="wrapped">
                    <div className="col col-12" style={{ marginBottom: '15px' }}>
                        <Textarea
                            rows={7}
                            name={formData.message}
                            value={formData.message}
                            placeholder={t('common.chat.write-message') || ''}
                            onChange={v => {
                                setFormData({
                                    ...formData,
                                    message: v.target.value
                                })
                            }}
                        />
                    </div>
                    <div className='col col-12'>
                        <PaperClip
                            name="photo"
                            value={formData.files}
                            pendingText={t('common.billboard.add_file') || ''}
                            successText={t('common.billboard.file_log') || ''}
                            onChange={(e: any) => {
                                setFormData({
                                    ...formData,
                                    files: e.target
                                })
                            }}
                        />
                    </div>
                </div>

                <div className="wrapped" style={{ marginTop: 10 }}>
                    <div className="actions" style={{ width: '100%', alignSelf: 'center' }}>
                        <button className="btn--primary" style={{ width: '40%' }} onClick={submit}>
                            <span>{t('common.globals.send')}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NextStepTechList;