/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { RootState } from 'reducers';
import { ChatService } from 'services';
import { Socket, socketEvents, Colors } from 'utils';
import * as actions from 'actions'
import {
  MenuChat,
  MenuCommissionsServices,
  MenuCustomers,
  MenuOperations,
  MenuParts,
  MenuScheduledAppointments,
  MenuServices,
  MenuTechnicians,
  MenuUsers,
  MenuList,
  QuotesIcon,
} from '../../assets/icons'
import { Logo } from 'assets/img';
import NotificationSound from 'assets/sounds/notification.mp3';
import { Modal } from 'components';
import ModalLanguage from './modal-language';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { on, emit } from 'jetemit';

const Routes = {
  users: '/users',
  technicians: '/technicians',
  customers: '/customers',
  services: '/services',
  parts: '/parts',
  operations: '/operations',
  chat: '/chat',
  commissionsServices: '/commissions-services',
  scheduledAppointments: '/scheduled-appointments',
  visitCommission: '/visit-commission',
  poster: '/poster',
  quotes: '/quotes'
}

interface Props {
  children: ReactElement
}
interface MessagesProps {
  id: number,
  sender_id: number,
  chat_session_id: number,
  message: string,
  mine?: boolean
}

const useFetch = (user: any) => {
  const [chats, setChats] = useState<number[]>([]);

  const getChats = async () => {
    const data = await ChatService.getChats({ user_id: user.id });
    const format = data as { chats: [] };

    setChats(format?.chats.map((x: any) => x?.item?.chat_session_id || 0));
  }

  useEffect(() => {
    getChats();
  }, []);

  return {
    chats,
    reload: getChats,
  }
}

const LayoutAdmin = ({ children }: Props) => {
  return (
    <div id="layout">
      <SideBar />
      <div className="wrapper">
        <Header />
        <main className="content">
          {children}
        </main>
      </div>
    </div>
  )
}

const SideBar = () => {

  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user);
  const currRoute = useLocation();
  const { chats, reload } = useFetch(user);
  const [showChatBadge, setShowChatBadge] = useState(false);
  const [navigation, setNavigation] = useState([
    { name: (t('common.header_menu.user')).toUpperCase(), to: Routes.users, icon: MenuUsers },
    { name: (t('common.header_menu.tech')).toUpperCase(), to: Routes.technicians, icon: MenuTechnicians },
    { name: (t('common.header_menu.clients')).toUpperCase(), to: Routes.customers, icon: MenuCustomers },
    { name: (t('common.header_menu.service')).toUpperCase(), to: Routes.services, icon: MenuServices },
    { name: (t('common.header_menu.spare')).toUpperCase(), to: Routes.parts, icon: MenuParts },
    { name: (t('common.header_menu.operations')).toUpperCase(), to: Routes.operations, icon: MenuOperations },
    { name: (t('common.header_menu.chat')).toUpperCase(), to: Routes.chat, icon: MenuChat },
    { name: (t('common.header_menu.commissions')).toUpperCase(), to: Routes.commissionsServices, icon: MenuCommissionsServices },
    { name: (t('common.header_menu.schedule_orders')).toUpperCase(), to: Routes.scheduledAppointments, icon: MenuScheduledAppointments },
    { name: (t('common.header_menu.revision_cost')).toUpperCase(), to: Routes.visitCommission, icon: MenuCommissionsServices },
    { name: (t('common.header_menu.billboard')).toUpperCase(), to: Routes.poster, icon: MenuList },
    { name: (t('common.header_menu.quotes')).toUpperCase(), to: Routes.quotes, icon: QuotesIcon },
  ]);

  const showMessageNotification = () => {
    const audio = new Audio(NotificationSound);
    audio.play();
    setShowChatBadge(true);
  }

  useEffect(() => {
    if (showChatBadge && currRoute.pathname === Routes.chat) {
      setShowChatBadge(false);
    }

    on(socketEvents.LANG.UPDATE, () => {
      setNavigation([
        { name: (t('common.header_menu.user')).toUpperCase(), to: Routes.users, icon: MenuUsers },
        { name: (t('common.header_menu.tech')).toUpperCase(), to: Routes.technicians, icon: MenuTechnicians },
        { name: (t('common.header_menu.clients')).toUpperCase(), to: Routes.customers, icon: MenuCustomers },
        { name: (t('common.header_menu.service')).toUpperCase(), to: Routes.services, icon: MenuServices },
        { name: (t('common.header_menu.spare')).toUpperCase(), to: Routes.parts, icon: MenuParts },
        { name: (t('common.header_menu.operations')).toUpperCase(), to: Routes.operations, icon: MenuOperations },
        { name: (t('common.header_menu.chat')).toUpperCase(), to: Routes.chat, icon: MenuChat },
        { name: (t('common.header_menu.commissions')).toUpperCase(), to: Routes.commissionsServices, icon: MenuCommissionsServices },
        { name: (t('common.header_menu.schedule_orders')).toUpperCase(), to: Routes.scheduledAppointments, icon: MenuScheduledAppointments },
        { name: (t('common.header_menu.revision_cost')).toUpperCase(), to: Routes.visitCommission, icon: MenuCommissionsServices },
        { name: (t('common.header_menu.billboard')).toUpperCase(), to: Routes.poster, icon: MenuList },
        { name: (t('common.header_menu.quotes')).toUpperCase(), to: Routes.quotes, icon: QuotesIcon },
      ])
    });
  }, [currRoute]);

  useEffect(() => {
    Socket.addEventListener(socketEvents.CHAT.NEW_CHAT, () => reload());
    Socket.addEventListener(socketEvents.CHAT.NEW_MESSAGE, (data: MessagesProps[]) => {
      const isMine = chats.some(x => x === data[0].chat_session_id);
      if (isMine && currRoute.pathname !== Routes.chat)
        showMessageNotification();
    });

    return () => {
      Socket.removeEventListener(socketEvents.CHAT.NEW_CHAT);
      Socket.removeEventListener(socketEvents.CHAT.NEW_MESSAGE);
    }
  }, [chats, currRoute]);

  return (
    <div id="sidebar">
      <div className="content">
        <div className="logo">
          <img src={Logo} alt="Logo" />
        </div>
        <nav>
          <div className="menu-items">
            {navigation.map(item => {

              const isSelected = currRoute.pathname === item.to;

              return (
                <Link
                  key={item.to}
                  to={item.to}
                  className={`menu-item ${isSelected ? 'menu-item-selected' : ''}`}
                >
                  <img src={item.icon} alt={item.name} />
                  {item.name}
                  {(showChatBadge && item.to === Routes.chat) && (
                    <div className="circle pulse" />
                  )}
                </Link>
              )
            })}
          </div>
        </nav>
      </div>
    </div>
  )
}

const Header = () => {

  const currRoute = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showLanguageModal, setLanguageModal] = useState(false);
  const [navigation, setNavigation] = useState([
    { name: (t('common.header_menu.user')).toUpperCase(), to: Routes.users, icon: MenuUsers },
    { name: (t('common.header_menu.tech')).toUpperCase(), to: Routes.technicians, icon: MenuTechnicians },
    { name: (t('common.header_menu.clients')).toUpperCase(), to: Routes.customers, icon: MenuCustomers },
    { name: (t('common.header_menu.service')).toUpperCase(), to: Routes.services, icon: MenuServices },
    { name: (t('common.header_menu.spare')).toUpperCase(), to: Routes.parts, icon: MenuParts },
    { name: (t('common.header_menu.operations')).toUpperCase(), to: Routes.operations, icon: MenuOperations },
    { name: (t('common.header_menu.chat')).toUpperCase(), to: Routes.chat, icon: MenuChat },
    { name: (t('common.header_menu.commissions')).toUpperCase(), to: Routes.commissionsServices, icon: MenuCommissionsServices },
    { name: (t('common.header_menu.schedule_orders')).toUpperCase(), to: Routes.scheduledAppointments, icon: MenuScheduledAppointments },
    { name: (t('common.header_menu.revision_cost')).toUpperCase(), to: Routes.visitCommission, icon: MenuCommissionsServices },
    { name: (t('common.header_menu.billboard')).toUpperCase(), to: Routes.poster, icon: MenuList },
    { name: (t('common.header_menu.quotes')).toUpperCase(), to: Routes.quotes, icon: QuotesIcon },
  ]);
  const lang = useSelector((state: RootState) => state.language);

  let breadcumbs = navigation.find(x => x.to === currRoute.pathname)?.name || '';
  breadcumbs = breadcumbs.toLowerCase();

  const logout = () => {
    dispatch(actions.logout());
  }

  const selectLanguage = () => {
    setLanguageModal(true)
  }

  useEffect(() => {
    on(socketEvents.LANG.UPDATE, () => {
      setNavigation([
        { name: (t('common.header_menu.user')).toUpperCase(), to: Routes.users, icon: MenuUsers },
        { name: (t('common.header_menu.tech')).toUpperCase(), to: Routes.technicians, icon: MenuTechnicians },
        { name: (t('common.header_menu.clients')).toUpperCase(), to: Routes.customers, icon: MenuCustomers },
        { name: (t('common.header_menu.service')).toUpperCase(), to: Routes.services, icon: MenuServices },
        { name: (t('common.header_menu.spare')).toUpperCase(), to: Routes.parts, icon: MenuParts },
        { name: (t('common.header_menu.operations')).toUpperCase(), to: Routes.operations, icon: MenuOperations },
        { name: (t('common.header_menu.chat')).toUpperCase(), to: Routes.chat, icon: MenuChat },
        { name: (t('common.header_menu.commissions')).toUpperCase(), to: Routes.commissionsServices, icon: MenuCommissionsServices },
        { name: (t('common.header_menu.schedule_orders')).toUpperCase(), to: Routes.scheduledAppointments, icon: MenuScheduledAppointments },
        { name: (t('common.header_menu.revision_cost')).toUpperCase(), to: Routes.visitCommission, icon: MenuCommissionsServices },
        { name: (t('common.header_menu.billboard')).toUpperCase(), to: Routes.poster, icon: MenuList },
        { name: (t('common.header_menu.quotes')).toUpperCase(), to: Routes.quotes, icon: QuotesIcon },
      ])
    });
  })

  return (
    <div id="header">

      <Modal
        title={t('common.language.change_language')}
        titleClassName="text-white"
        size="sm"
        visible={showLanguageModal}
        onClose={() => setLanguageModal(false)}
        headerStyle={{ background: Colors.blue }}
      >
        <ModalLanguage
          onClose={() => {
            setLanguageModal(false)
          }}
        />
      </Modal>

      <div className="content">
        <div className="breadcumbs">
          <span>{breadcumbs}</span>
        </div>
        <div className="actions">
          <button type="button" onClick={selectLanguage}>
            <span style={{ fontWeight: 'bold', color: Colors.blue }}>{lang.lang.toUpperCase()}</span>
          </button>
          <button type="button" onClick={logout}>
            <span>{t('common.header.logout')}</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default LayoutAdmin;