import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { on, emit } from 'jetemit';

interface Props {
	label?: string,
	name: string,
	color?: string,
	options: Item[],
	placeholder?: string,
	onChange: (e: any) => void,
	value: any,
	disabled?: boolean
}

interface Item {
	value: string,
	label: string
}

const Select = (props: Props) => ( 
	<div className="form-group">
		{ props.label && <label htmlFor={ props.name }>{ props.label }</label> }
		<select
			{ ...props }
			className={ `form-control ${ props.color ? props.color : '' }` }
			onChange={v => props.onChange(v.target.value)}
		>
			<option value="" disabled={ !props.placeholder }>{ props.placeholder || i18n.t('common.globals.select') }</option>
			{ props.options.map((i: Item,index: number) => {
				return (
					<option 
						key={ `option-${props.name}${index}` } 
						value={ i.value }
						title={ i.label }
					>
						{ i.label }
					</option>
				)
			}) }
		</select>
	</div>
)

export default Select;