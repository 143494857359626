/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import moment from 'moment';
import { Input, Textarea, PaperClip } from 'components';
import { OperationService, ServiceService } from 'services';
import { Constants, Globals, Colors, Socket, socketEvents } from 'utils';
import { IList, IServiceType } from 'types';
import {
    SpanishIcon,
    EnglishIcon
} from 'assets/icons';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import * as actions from 'actions';
import { on, emit } from 'jetemit';

interface Props {
    item?: any
    onClose?: any
}

interface FormProps {
    service_id: string
    date: string
    message: string
    files: any
}

const ModalLanguage = ({ onClose }: Props) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState<FormProps>({ service_id: '', date: '', message: '', files: null });
    const dispatch = useDispatch();
    
    const searchServices = async () => {
        try {
            const res: any = await ServiceService.get({ per_page: 20, page: 1 });
            if (!res?.data || res?.error) return Globals.showError();

        } catch (error) {
            console.log(error)
            Globals.showError();
        }
    }

    useEffect(() => {
        searchServices();
    }, []);

    return (
        <div id="admin-cs-modal-cs-details">
            <div className="content">
                <button className='col wrapped col-12' style={{ background: 'white', border: 'none', padding: 40 }} onClick={() => {
                    i18n.changeLanguage('es');
                    dispatch(actions.setLang({ lang: 'es' }));
                    emit(socketEvents.LANG.UPDATE)
                }}>
                    <img src={SpanishIcon} alt="Spain" style={{ width: 25, height: 25, marginRight: 10 }} />
                    <div className="label">{t('common.language.spanish')}</div>
                </button>
                <button className='col wrapped col-12' style={{ marginTop: 20, background: 'white', border: 'none', padding: 40 }} onClick={() => {
                    i18n.changeLanguage('en')
                    dispatch(actions.setLang({ lang: 'en' }));
                    emit(socketEvents.LANG.UPDATE)
                }}>
                    <img src={EnglishIcon} alt="English" style={{ width: 25, height: 25, marginRight: 10 }} />
                    <div className="label">{t('common.language.english')}</div>
                </button>

                <div className="wrapped" style={{ marginTop: 10 }}>
                    <div className="actions" style={{ width: '100%', alignSelf: 'center' }}>
                        <button className="btn--primary" style={{ width: '40%' }} onClick={() => onClose(true)}>
                            <span>{t('common.language.closed').toUpperCase()}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalLanguage;