import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Select, Modal } from 'components';
import { ICustomer } from 'types';
import { Globals } from 'utils';
import { MapsPin, View } from 'assets/icons';
import { CustomerService } from 'services';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

interface Props {
  user?: ICustomer
  onClose?: (reloading: boolean) => void
}

const UserDetails = ({ user }: Props) => {
  const { t } = useTranslation();
  const [form, setForm] = useState({ propertyId: '' });
  const [selectedProperty, setSelectedProperty] = useState<any>();
  const [viewSerialModal, setViewModalSerial] = useState<boolean>(false);
  const [serialSelected, setSerialSelected] = useState<any>();
  const [lastService, setLastService] = useState<any>([]);
  const [propertiesEquipment, setPropertiesEquipment] = useState<any>();

  const onSelectProperty = (propertyId: string) => {
    setForm({ propertyId: propertyId })
    if (!propertyId) {
      setSelectedProperty(undefined);
      return;
    }
    const property = user?.properties?.find(x => x.id === Number(propertyId));

    setSelectedProperty(property);
    setPropertiesEquipment(property?.properties_equipments);

    const equipment = property?.properties_equipments?.map(item => item.id);
    getLastService(equipment, property?.properties_equipments);
  }

  const openMaps = () => {
    if (!selectedProperty.latitude || !selectedProperty.longitude)
      return Globals.showError(t('common.globals.property.property_location_not_found'));

    window.open(`https://google.com/maps?q=${selectedProperty.latitude},${selectedProperty.longitude}&z=16`,'_blank');
  }

  const viewSerial = (property: any) => {
    const photo = Globals.fromPhotos(property.photo_serial);
    setSerialSelected(photo);
    setViewModalSerial(true)
  }

  const getLastService = async (equipment: number[] | undefined, properties_equipments: any) => {
    if (equipment !== undefined) {
      for await (const id of equipment) {

        const res: any = await CustomerService.service.getLastService({
          client_id: user?.id,
          equipment_id: id
        });
        if (res.data) {

          const propertiesEquip = properties_equipments?.map((item: any) => {
            if (item.id === res.data?.equipment_id) {
              item.service = res.data?.service;
              item.tech = res.data?.tech;
              item.last_service = res.data?.last_service;
              item.order_id = res.data?.order_id
            }
            return item;
          });

          setPropertiesEquipment(propertiesEquip)
  
        }
      }
    }
  }

  if (!user) return (
    <div className="flex-center">
      <h5>Debe seleccionar un cliente</h5>
    </div>
  )

  return (
    <div id="admin-customers-modal-user-details">
      <Modal
        title={'Serial'}
        titleClassName="text-blue"
        size="xl"
        visible={viewSerialModal}
        onClose={() => setViewModalSerial(false)}
      >
        <div className='serial-content'>
          <img className='d-block m-auto' src={serialSelected} alt="Photo serial" style={{ width: '50%', height: '50%' }} />
        </div>
      </Modal>
      <div className="content">
        <div className="wrapped">
          <div className="prop col col-4">
            <div className="label">{t('common.clients.phone')}</div>
            <div>{user.person.phone}</div>
          </div>
          <div className="prop col col-4">
            <div className="label">{t('common.clients.email')}</div>
            <div style={{ paddingRight: '0.5rem', wordBreak: 'break-all' }}>
              {user.email}
            </div>
          </div>
          <div className="prop col col-4">
            <div className="label">{t('common.clients.client_type')}</div>
            <div>{t(Globals.getLevelName(user.level.id))}</div>
          </div>
          {user.enterprise && (
            <>
              <div className="prop col col-4">
                <div className="label">{t('common.clients.company_name')}</div>
                <div>{user?.enterprise?.name}</div>
              </div>
              <div className="prop col col-4">
                <div className="label">{t('common.clients.company_phone')}</div>
                <div>{user?.enterprise?.phone}</div>
              </div>
              <div className="prop col col-4">
                <div className="label">{t('common.clients.company_email')}</div>
                <div>{user?.enterprise?.email}</div>
              </div>
            </>
          )}
          <div className="prop col col-12">
            <div className="label">{t('common.globals.location')}</div>
            <div>{user.person.address || user.enterprise?.address}</div>
          </div>

          <div className="prop col col-12 divider">
            <div className="section-label text-blue">{t('common.clients.amount_properties_registered')}</div>
          </div>

          <div className="wrapped">
            <div className="prop col col-4">
              <div className="label">{t('common.globals.property.property_data')}</div>
              <Select
                name="property"
                options={
                  !!user?.properties?.length
                    ? user?.properties?.map(x => ({ value: x.id.toString(), label: x.name || '' }))
                    : []
                }
                value={form.propertyId}
                onChange={onSelectProperty}
              />
            </div>
            {!!selectedProperty && (
              <>
                <div className="prop col col-1" />

                <div className="prop col col-3">
                  <div className="label">{t('common.globals.type')}</div>
                  <div>{Globals.getPropertyTypeName(selectedProperty.type)}</div>
                </div>

                <div className="prop col col-3">
                  <button className="maps-location" onClick={openMaps}>
                    <img src={MapsPin} alt="Google Maps Pin" />
                    <span>{t('common.globals.show_location')}</span>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>

        {!!selectedProperty && (
          <>
            <div className="wrapped">
              {/* <div className="prop col col-3">
                <div className="label">Estado</div>
                <div>{selectedProperty.state}</div>
              </div>
              <div className="prop col col-3">
                <div className="label">Ciudad</div>
                <div>{selectedProperty.city}</div>
              </div> */}

              <div className="prop col col-12">
                <div className="label">{t('common.globals.address')}</div>
                <div>{selectedProperty.address}</div>
              </div>

              <div className="prop col col-3">
                <div className="label">{t('common.globals.floor')}</div>
                <div>{selectedProperty.piso}</div>
              </div>
              <div className="prop col col-3">
                <div className="label">{t('common.globals.area')}</div>
                <div>{selectedProperty.area}</div>
              </div>

              <div className="prop col col-12">
                <span>{t('common.clients.equipment_info')}</span>
              </div>
            </div>

            <div className="wrapped">
              <table>
                <thead>
                  <tr>
                    <th>{t('common.clients.model')}</th>
                    <th>{t('common.clients.serial')}</th>
                    <th>{t('common.clients.installation_year')}</th>
                    <th>{t('common.clients.service_type')}</th>
                    <th>{t('common.clients.tech')}</th>
                    <th>{t('common.clients.last_service')}</th>
                    <th>{t('common.clients.serial')}</th>
                  </tr>
                </thead>
                <tbody>
                  {propertiesEquipment.map((x: any) => (
                    <tr key={`equipment-${x.id}`}>
                      <td>{x.equipment?.name}</td>
                      <td>{x.serial}</td>
                      <td>{moment(x.installation_year).format('YYYY-MM-DD')}</td>
                      <td>{x?.order_id !== undefined ? x?.service_id || t('common.globals.revision') : ''}</td>
                      <td>{x?.tech?.name} {x?.tech?.lastname}</td>
                      <td>{x?.last_service ? moment(x?.last_service).format('YYYY-MM-DD') : ''}</td>
                      <td className="row-actions">
                        {
                          x.photo_serial && (
                            <img
                              className="action-icon cursor-pointer m-auto d-block"
                              style={{ width: '30px', height: '30px' }}
                              src={View}
                              alt=""
                              onClick={() => viewSerial(x)}
                            />
                          )
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default UserDetails;