import React from 'react';
import { Modal } from 'react-bootstrap';
import { Globals } from 'utils';
import { CloseIcon } from 'assets/icons';

const _Modal = React.forwardRef((props: any, ref) => (
	<Modal
		scrollable={true}
		size={props.size}
		backdrop="static"
		ref={ref as React.RefObject<any>}
		id={props.id}
		className={props.className}
		onShow={Globals.hideTooltip}
		show={props.visible}
		onHide={props.onClose}
	>
		<Modal.Header closeButton={ !props.closeButton ? true : false } style={props.headerStyle}>
			<Modal.Title className={props?.titleClassName}>{props.title}</Modal.Title>
			{ props.customCloseButton && (
				<button onClick={props.onClose} style={{ background: 'inherit', border: 'inherit' }}>
					<img src={CloseIcon} style={{ width: 25, height: 25 }} />
				</button>
			) }
		</Modal.Header>
		<Modal.Body>{props.children}</Modal.Body>
	</Modal>
))

export default _Modal;