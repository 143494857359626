import { Api } from 'utils';

class PartService {

	static get = async (params = {}) => Api.createResponse('spareparts/get',params);
	static create = async (params = {}) => Api.createResponse('spareparts/create',params);
	static update = async (params = {}) => Api.createResponse('spareparts/update',params);
	static view = async (params = {}) => Api.createResponse('spareparts/view',params);
	static delete = async (params = {}) => Api.createResponse('spareparts/destroy',params);

}

export default PartService;