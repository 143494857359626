/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Input } from 'components';
import { Edit, Search, Trash } from 'assets/icons';
import { Globals } from 'utils';
import { ServiceService } from 'services';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

interface CreateEquipmentProps {
  onClose: (reloading: boolean) => void
}

interface IForm {
  id?: number | undefined
  name: string
  original?: string
  search?: string
}

const initialForm: IForm = {
  name: '',
  search: '',
};

const useFetch = () => {
  const { t } = useTranslation();

  const initialForm = {
    search: '',
    page: 1,
    per_page: 20,
    last_page: 1,
    item_count: 0,
  };

  const [data, setData] = useState<IForm[]>([]);
  const [canFetch, setCanFetch] = useState(true);
  const [pagination, setPagination] = useState(initialForm);

  const debounceTime = 500;
  let debounce: any = null;

  const fetchData = async () => {
    if (!canFetch) return;

    try {
      const res: any = await ServiceService.get({
        page: pagination.page,
        per_page: pagination.per_page,
        search: pagination.search,
      });

      if (!res?.data?.equipments)
        return Globals.showError(t('common.globals.toast-error'));

      setData(res.data.equipments);

    } catch (error) {
      console.log(error);
      Globals.showError(t('common.globals.toast-error'));
    }
  }

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    inSamePage ? fetchData() : setPagination(initialForm);
  }

  const prevPage = () => {
    if (pagination.page > 1) {
      setCanFetch(true);
      setPagination({ ...pagination, page: pagination.page + 1 });
    }
  }

  const nextPage = () => {
    if (pagination.page < pagination.last_page) {
      setCanFetch(true);
      setPagination({ ...pagination, page: pagination.page + 1 });
    }
  }

  const goToPage = (page: number) => {
    if (page >= 1 && page <= pagination.last_page && page !== pagination.page) {
      setCanFetch(true);
      setPagination({ ...pagination, page });
    }
  }

  const search = (search: string) => {
    if (debounce) clearTimeout(debounce);
    debounce = setTimeout(() => {
      setCanFetch(true);
      setPagination({ ...pagination, page: 1, search });
    }, debounceTime);
  }

  useEffect(() => {
    fetchData();
  }, [pagination]);

  return {
    equipments: data,
    pagination,
    nextPage,
    prevPage,
    goToPage,
    reload,
    search,
  }
}

const CreateEquipment = ({ onClose }: CreateEquipmentProps) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<IForm>(initialForm);
  const { equipments, reload } = useFetch();

  const onChange = (target: keyof typeof formData, value: string) => {
    setFormData(s => ({ ...s, [target]: value }))
  }

  const editEquipment = (item: IForm) => {
    setFormData({ ...item, original: item.name });
  }

  const deleteEquipment = (item: IForm) => {
    const doIt = async () => {
      try {
        const res: any = await ServiceService.deleteEquipment({ id: item.id });
        if (res?.error) return Globals.showError(res.error);

        Globals.showSuccess(t('common.globals.product_deleted'));
        setFormData(initialForm);
        reload();

      } catch (error) {
        console.log(error);
        Globals.showError();
      }
    }

    Globals.confirm(`${t('common.globals.product-tech-confirm')} ${item.name}`, doIt);
  }

  const submit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!isValidForm()) return;

    try {
      const res: any = !!formData.id
        ? await ServiceService.updateEquipment(formData)
        : await ServiceService.createEquipment(formData);

      if (res?.error) return Globals.showError(res.error);
      if (!res?.equipment) return Globals.showError();

      Globals.showSuccess(`${t('common.globals.equipment')} ${!!formData.id ? t('common.globals.edited') : t('common.globals.added')}`);
      setFormData(initialForm);
      reload();

    } catch (error) {
      console.log(error);
      Globals.showError();
    }
  }

  const isValidForm = (): boolean => {
    const onError = (msg: string) => {
      Globals.showError(msg);
      return false;
    }

    if (!formData.name)
      return onError(t('common.service.form.require_name'));

    return true;
  }

  const filtered = () => {
    return !!formData.search
      ? equipments.filter(x => x.name.toLocaleLowerCase().includes(formData.search?.toLocaleLowerCase() || ''))
      : equipments;
  }

  return (
    <div id="admin-services-modal-service-details" className="content">
      <form onSubmit={submit}>
        <div className="form--group">
          <div style={{ flex: 1 }}>
            <Input
              label={t('common.service.name') || ''}
              placeholder={!!formData?.id ? formData.original : t('common.globals.equipment_name')}
              value={formData.name}
              onChange={v => onChange('name', v)}
            />
          </div>

          <div style={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '1rem' }}>
            <button className="form--submit-button" type="submit">
              <span>{!!formData?.id ? t('common.globals.save') : t('common.globals.save')}</span>
            </button>
          </div>
        </div>
      </form>

      <div className="divider mb-3" />

      <Input
        filterStyle
        placeholder={t('common.globals.save')}
        value={formData.search}
        onChange={v => onChange('search', v)}
        preppend={<img src={Search} alt="Buscar"/>}
      />

      <div className="divider" />

      {!!filtered().length ? (
        <div style={{ maxHeight: 200, overflowY: 'auto' }}>
          <div className="wrapped">
            <table>
              <thead>
                <tr>
                  <th>{t('common.service.name')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filtered().map((x: any, index: number) => (
                  <tr key={`equipment-${index}`}>
                    <td className={`${ formData.id === x.id ? 'text-red' : '' }`}>
                      { x.name }
                    </td>
                    <td className="row-actions">
                      <img
                        className="action-icon"
                        src={Edit}
                        alt=""
                        onClick={() => editEquipment(x)}
                      />
                      <img
                        className="action-icon"
                        src={Trash}
                        alt=""
                        onClick={() => deleteEquipment(x)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="flex-center">
          <div className="no-items">{t('common.service.not_data_found')}</div>
        </div>
      )}
    </div>
  )
}

export default CreateEquipment;