import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Input } from 'components';
import { AuthService } from 'services';
import { Globals } from 'utils';
import { ArrowLeft, RecoveryCode } from 'assets/icons';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const ConfirmedEmail = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const [formData, setFormData] = useState({ code: '' });

  const onChange = (target: keyof typeof formData, value: string) => {
    setFormData(s => ({ ...s, [target]: value.toLowerCase().toUpperCase() }))
  }

  const isValidForm = (): boolean => {
    const onError = (msg: string) => {
      Globals.showError(msg);
      return false;
    }

    if (!formData.code) return onError('El código es obligatorio');

    return true;
  }

  const submit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!isValidForm()) return;

    try {
      const res: any = await AuthService.recoverCode(formData);

      if (res?.error) return Globals.showError(res.error);
      if (!res?.result) return Globals.showError('Código no válido');

      history.push(`/recover/password/${formData.code}`);

    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div id="recover">
      <div className="form-wrapper">
        {/* <button type="button" className="go-back" onClick={() => history.goBack()}>
          <img src={ArrowLeft} alt="Volver" />
        </button> */}

        {/* <div className="icon">
          <img src={RecoveryCode} alt="Icono" />
        </div> */}

        <form onSubmit={submit}>

          <p className="info text-center">{t('common.confirm_email.confirmed')}</p>

          <p className="info text-center">{t('common.confirm_email.txt')}</p>

          {/* <div className="submit">
            <button className="btn--primary" type="submit">
              <span>Enviar</span>
            </button>
          </div> */}
        </form>
      </div>
    </div>
  )
}

export default ConfirmedEmail;