/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ExcelJS from 'exceljs';
import { DownloadIcon, Report, View } from '../../../assets/icons';
import { Input, Modal, Pagination, Select, SelectSearch } from '../../../components'
import CSDetails from './CSDetails';
import { OperationService, ServiceService } from 'services';
import { Globals, Constants, socketEvents } from 'utils';
import { IList, IServiceType } from 'types';
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { on, emit } from 'jetemit';

interface IForm {
  init_date: string | undefined
  end_date: string | undefined
  service_id: string | null
  level_id: string | null
  client_id: string | null
  tech_id: string | null
};

const initialForm: IForm = {
  init_date: '',
  end_date: '',
  service_id: '',
  tech_id: '',
  level_id: '',
  client_id: ''
};

interface IRevision {
  amount: number | undefined
  tech_percentage: number | undefined
};

const initRevision: IRevision = {
  amount: 0,
  tech_percentage: 0
};


const useFetch = () => {
  const { t } = useTranslation();
  const initialForm = {
    search: '',
    init_date: null,
    end_date: null,
    service_id: null,
    level_id: null,
    tech_id: null,
    client_id: null,
    page: 1,
    per_page: 20,
    last_page: 1,
    item_count: 0,
  };

  const initRevision = {
    amount: 0,
    tech_percentage: 0
  };

  const [data, setData] = useState<any[]>([]);
  const [revision_price, setRevisionPrice] = useState(initRevision);
  const [totals, setTotals] = useState({
    services_count: 0,
    services_money: 0,
    commissions_total: 0,
  })
  const [selects, setSelects] = useState<any>({
    techs: [],
    clients: [],
    services: [],
  });
  const [canFetch, setCanFetch] = useState(true);
  const [pagination, setPagination] = useState(initialForm);

  const debounceTime = 500;
  let debounce: any = null;

  const getCost = (item: any) => {
    const cost = parseFloat(item?.cost || 0);
    let partsCost = 0;
    if (!!item?.details?.length) {
      partsCost = item.details
        .reduce((prev: number, curr: any) => prev + (parseFloat(curr.cost) * curr.qty), 0);
    }
    return parseFloat((cost + partsCost).toFixed(2));
  }

  const getCostFromService = (item: any) => {
    const equipment_service = item.equipment?.equipment?.services;
    const filter = equipment_service?.filter(function (i: any) {
      return i.service_id == item.service_id;
    }) || [];
    const value = filter[0]?.cost_service || 0;
    return parseFloat(value).toFixed(2);
  }

  const getCommissionFromService = (item: any) => {
    const equipment_service = item.equipment?.equipment?.services
    const filter = equipment_service?.filter(function (i: any) {
      return i.service_id == item.service_id;
    }) || [];
    
    const value = filter[0]?.commission || 0;
    if(value > 0){
      return ((Number(value) * filter[0].cost_service) / 100).toFixed(2)
    }
    else{
      return Number(value).toFixed(2)
    }
    
  }

  const fetchData = async () => {
    if (!canFetch) return;

    try {
      const res: any = await OperationService.getComissionsAndServices({
        page: pagination.page,
        per_page: pagination.per_page,
        search: pagination.search,
        init_date: pagination.init_date,
        end_date: pagination.end_date,
        service_id: pagination.service_id,
        level_id: pagination.level_id,
        tech_id: pagination.tech_id,
        client_id: pagination.client_id
      });

      if (!res?.data?.orders?.rows)
        return Globals.showError(t('common.globals.toast-error'));

      for await (const item of res?.data?.orders?.rows) {
        if(item?.equipment?.equipment?.id && item.service?.id){
          item.cost = (await serviceView(item))?.data?.cost_labor || item.cost;
        }
      }

      const last_page = res?.data?.orders?.count;
      setCanFetch(false);
      setPagination({
        ...pagination,
        item_count: res?.data?.orders?.count,
        last_page,
      });
      setData(res.data.orders.rows);
      setRevisionPrice(res.data.revision)
      setSelects({
        techs: res.data.techs,
        clients: res.data.clients,
        services: [...res.data.services, { id: null, name: t('common.globals.revision') }]
      });

      let total_service = 0;
      let total_commissions = 0;

      res.data.orders.rows.forEach((item: any) => {
        total_service += getCost(item);
        if (item?.service !== null) {
          total_commissions += (Number(item.commission) * item.cost) / 100
        } else {
          total_commissions += Number(item?.commission)
        }
      });

      setTotals({
        services_count: res.data.services_count,
        services_money: res.data.services_money,//total_service,
        commissions_total: res.data.commissions_total,//total_commissions,
      });

    } catch (error) {
      console.log(error);
      Globals.showError('Ocurrió un error inesperado');
    }
  }

  const serviceView = async (item: any) => {
    if (item?.service_id) {
      const equipment_service: any = await ServiceService.equipmentServiceView({
        equipment_id: item?.equipment?.equipment?.id,
        service_id: item.service?.id,
        withoutLoading: true
      });
      
      return equipment_service;
    }
  }

  const fetchToPrint = () => new Promise<any[]>((resolve, reject) => {
    OperationService.getComissionsAndServices({
      page: pagination.page,
      per_page: pagination.item_count,
      search: pagination.search,
      init_date: pagination.init_date,
      end_date: pagination.end_date,
      service_id: pagination.service_id,
      level_id: pagination.level_id,
      tech_id: pagination.tech_id,
    })
      .then((res: any) => {
        if (!res?.data?.orders?.rows)
          return [];

        setCanFetch(false);
        resolve(res.data.orders.rows);
      })
      .catch(reject);
  });

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setPagination(initialForm);
    fetchData();
  }

  const prevPage = () => {
    if (pagination.page > 1) {
      setCanFetch(true);
      setPagination({ ...pagination, page: pagination.page + 1 });
    }
  }

  const nextPage = () => {
    if (pagination.page < pagination.last_page) {
      setCanFetch(true);
      setPagination({ ...pagination, page: pagination.page + 1 });
    }
  }

  const goToPage = (page: number) => {
    if (page >= 1 && page <= pagination.last_page && page !== pagination.page) {
      setCanFetch(true);
      setPagination({ ...pagination, page });
    }
  }

  const search = (target: keyof IForm, value: string) => {
    if (debounce) clearTimeout(debounce);
    debounce = setTimeout(() => {
      setCanFetch(true);
      setPagination({ ...pagination, page: 1, [target]: value });
    }, debounceTime);
  }

  const forPrint = async () => {
    setCanFetch(true);
    const res = await fetchToPrint();
    return res || [];
  }

  useEffect(() => {
    fetchData();
  }, [pagination]);

  return {
    orders: data,
    revision: revision_price,
    selects,
    totals,
    pagination,
    nextPage,
    prevPage,
    goToPage,
    reload,
    search,
    forPrint,
    getCost,
    getCostFromService,
    getCommissionFromService,
  }
}

const CommissionsServices = () => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState<IForm>(initialForm);
  const { orders, selects, totals, pagination, reload, goToPage, search, forPrint, getCost, getCostFromService, revision, getCommissionFromService } = useFetch();
  const [selectedItem, setSelectedItem] = useState<any>();
  const [clientTypesOptions, setClientTypesOptions] = useState([
    { value: Constants.USER.LEVEL.OWNER.toString(), label: t('common.globals.owner') },
    { value: Constants.USER.LEVEL.RENTER.toString(), label: t('common.globals.renter') },
    { value: Constants.USER.LEVEL.PROPERTY_ADMIN.toString(), label: t('common.globals.admin') }
  ]);

  const onChange = (target: keyof IForm, value: any) => {
    setFormData(s => ({ ...s, [target]: value }));
    search(target, (value?.value || value));
  }

  const resetForm = () => {
    setFormData(initialForm);
    reload();
  }

  const viewItem = (item: any) => {
    setSelectedItem(item);
    setModal(true);
  }

  const downloadInvoice = (service: any) => {
    OperationService.historyServicePDF({
      order_id: service?.id,
      bill: true,
      onlyOrder: true,
    })
      .then((res: any) => {
          const url = Globals.fromPhotos(res.quotation);
          const element = document.createElement('a');
          element.href = url;
          element.download = t('common.globals.service') + ' ' + service?.id;
          element.target = '_blank';
          element.click();
      });
  }

  const onCloseModal = (reloading: boolean = false) => {
    if (reloading) reload();
    setModal(false);
  }

  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Hoja 1');

    worksheet.columns = [
      { key: 'date', header: t('common.operations.commissions.service_date') || '', width: 18, style: { numFmt: 'dd/mm/yyyy' } },
      { key: 'service_name', header: t('common.globals.service_type') || '', width: 20 },
      { key: 'tech_name', header: t('common.globals.tech') || '', width: 20 },
      { key: 'start_time', header: t('common.globals.init_date') || '', width: 20 },
      { key: 'client_name', header: t('common.globals.client') || '', width: 20 },
      { key: 'client_level', header: t('common.globals.client_type') || '', width: 20 },
      { key: 'cost', header: t('common.globals.cost') || '' },
      { key: 'commission', header: `${t('common.globals.commission') || ''} Below`, width: 20 },
    ];

    orders.forEach((row: any) => {
      const commission = !!row?.service ? getCommissionFromService(row) : ( Number(revision.tech_percentage) * Number(revision.amount) / 100 ).toFixed(2);
      worksheet.addRow({
        date: moment(row.created_at).format('DD-MM-YYYY'),
        service_name: row.service?.name ?? t('common.globals.revision'),
        tech_name: row.tech?.name,
        start_time: moment(row.start_time).isValid() ? moment(row.start_time).format('DD-MM-YYYY HH:mm') : null,
        client_name: row.client?.name,
        client_level: row.client?.level?.name,
        cost: !!row?.service ? getCostFromService(row) : (revision?.amount).toFixed(2),
        commission,
      });
    });

    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: 'octet/strem' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Comisiones y servicios.xlsx';
      link.click();
    });
  }

  useEffect(() => {
    on(socketEvents.LANG.UPDATE, () => {
      setClientTypesOptions([
        { value: Constants.USER.LEVEL.OWNER.toString(), label: t('common.globals.owner') },
        { value: Constants.USER.LEVEL.RENTER.toString(), label: t('common.globals.renter') },
        { value: Constants.USER.LEVEL.PROPERTY_ADMIN.toString(), label: t('common.globals.admin') }
      ]);
    })
  }, []);

  return (
    <div id="admin-commissions-services">
      <Modal
        title={`${selectedItem?.client?.name || ''} ${selectedItem?.client?.lastname || ''}`}
        titleClassName="text-blue"
        size="xl"
        visible={modal}
        onClose={() => onCloseModal(false)}
      >
        <CSDetails item={selectedItem} revision={revision} onClose={onCloseModal} />
      </Modal>

      <div className="search-bar">
        <div className="col col-3">
          <Input
            filterStyle
            noMargin
            type="date"
            label={t('common.globals.since') || ''}
            value={formData.init_date}
            onChange={v => onChange('init_date', v)}
          />
        </div>
        <div className="col col-3">
          <Input
            filterStyle
            noMargin
            type="date"
            label={t('common.globals.until') || ''}
            value={formData.end_date}
            onChange={v => onChange('end_date', v)}
          />
        </div>
        <div className="col col-3">
          <SelectSearch
            label={t('common.globals.tech') || ''}
            options={selects.techs.map((x: any) => ({
              value: x.id,
              label: `${x?.name || ''} ${x?.lastname || ''} (${x?.email || ''})`
            }))}
            value={formData.tech_id}
            onChange={(v: IList) => onChange('tech_id', v)}
          />
        </div>
        <div className="col col-3">
          <SelectSearch
            label={t('common.globals.client') || ''}
            options={selects.clients.map((x: any) => ({
              value: x.id,
              label: `${x?.name || ''} ${x?.lastname || ''} (${x?.email || ''})`
            }))}
            value={formData.client_id}
            onChange={(v: IList) => onChange('client_id', v)}
          />
        </div>
        <div className="col col-3">
          <Select
            label={t('common.globals.client_type') || ''}
            name="level_id"
            options={clientTypesOptions}
            value={formData.level_id}
            onChange={v => onChange('level_id', v)}
          />
        </div>
        <div className="col col-3">
          <Select
            label={t('common.globals.service_type') || ''}
            name="service_id"
            options={selects.services.map((x: IServiceType) => ({
              value: String(x.id),
              label: x.name,
            }))}
            value={formData.service_id}
            onChange={v => onChange('service_id', v)}
          />
        </div>
        <div className="col col-3" style={{ display: 'flex', alignItems: 'flex-end' }}>
          <button
            type="button"
            className="btn--primary" style={{ width: '100%', marginBottom: '1.1rem', marginRight: '1rem', backgroundColor: 'gray' }}
            onClick={() => resetForm()}
          >
            <span>{t('common.globals.clear_filters') || ''}</span>
          </button>
        </div>
      </div>

      <div className="custom-table">
        <div className="header">
          <div>
            <div className="">{t('common.operations.commissions.service_done')}</div>
            <h5 className="text-blue">{totals.services_count}</h5>
          </div>
          <div>
            <div className="">{t('common.operations.commissions.total_service')}</div>
            <h5 className="text-success">${totals.services_money.toFixed(2)}</h5>
          </div>
          <div>
            <div className="">{t('common.operations.commissions.total_commission')}</div>
            <h5 className="text-warning">${totals.commissions_total.toFixed(2)}</h5>
          </div>
          <div className="actions">
            <button className="btn--primary" onClick={() => downloadExcel()}>
              <img src={Report} width={24} alt="Exportar a Excel" style={{ marginRight: 10 }} />
              <span>{t('common.globals.export_excel')}</span>
            </button>
          </div>
        </div>

        {!!orders.length ? (
          <table>
            <thead>
              <tr>
                <th>{t('common.globals.schedule_date')}</th>
                <th>{t('common.globals.service_type')}</th>
                <th>{t('common.globals.tech')}</th>
                <th>{t('common.globals.init_date')}</th>
                <th>{t('common.globals.client')}</th>
                <th>{t('common.globals.client_type')}</th>
                <th>{t('common.globals.cost')}</th>
                <th>{t('common.globals.commission')} Below</th>
                <th className="text-right">{t('common.globals.actions')}</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((i, index) => (
                <tr key={`row-${index}`}>
                  <td>{moment(i.date || i.created_at).format('DD-MM-YYYY HH:mm')}</td>
                  <td>{i.service?.name ?? t('common.globals.revision')}</td>
                  <td>{i.tech?.name}</td>
                  <td>{moment(i.start_time).isValid() && moment(i.start_time).format('DD-MM-YYYY HH:mm')}</td>
                  <td>{i.client?.name}</td>
                  <td>{t(Globals.getLevelName(i.client?.level?.id))}</td>
                  {/* <td>{getCost(i)}</td>
                  <td>
                    {i?.service !== null ? (
                      (Number(i.commission) * i.cost) / 100
                    ) : (
                      i?.commission
                    )}
                  </td> */}
                  <td>
                    {
                      i.service !== null ?
                        getCostFromService(i)
                      :
                        (Number(revision?.amount) - ( Number(revision.tech_percentage) * Number(revision.amount) / 100 )).toFixed(2)
                    }
                  </td>
                  <td>
                    {i?.service !== null ? (
                      getCommissionFromService(i)
                    ) : (
                      ( Number(revision.tech_percentage) * Number(revision.amount) / 100 ).toFixed(2)
                    )}
                  </td>
                  <td className="row-actions right">
                    <img
                      className="action-icon cursor-pointer"
                      src={View}
                      alt=""
                      onClick={() => viewItem(i)}
                    />
                    <img
                      className="action-icon"
                      src={DownloadIcon}
                      alt="Action icon"
                      title={t('common.globals.download_invoice') || ''}
                      onClick={() => downloadInvoice(i)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="no-items">{t('common.operations.commissions.not_data_found')}</div>
        )}
      </div>

      <div className="wrapped">
        <Pagination
          active={pagination.page}
          pages={pagination.last_page}
          onChange={goToPage}
        />
      </div>
    </div>
  )
}

export default CommissionsServices;