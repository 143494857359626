/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { PartService } from 'services';
import { IPartCategory } from 'types';
import { Globals } from 'utils';
import { Search, Trash, Add } from '../../../assets/icons';
import { Modal, Pagination } from '../../../components'
import CreatePart from './CreatePart';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const useFetch = () => {
  const { t } = useTranslation();

  const initialForm = {
    search: '',
    page: 1,
    per_page: 20,
    last_page: 1,
    item_count: 0,
  };

  const [data, setData] = useState<IPartCategory[]>([]);
  const [canFetch, setCanFetch] = useState(true);
  const [pagination, setPagination] = useState(initialForm);

  const debounceTime = 500;
  let debounce: any = null;

  const fetchData = async () => {
    if (!canFetch) return;

    try {
      const res: any = await PartService.get({
        page: pagination.page,
        per_page: pagination.per_page,
        search: pagination.search,
      });

      if (!res?.data?.rows)
        return Globals.showError(t('common.globals.toast-error'));

      const last_page = Math.ceil((res?.data?.rows?.length || 0) / pagination.per_page);
      setCanFetch(false);
      setPagination({
        ...pagination,
        item_count: res?.data?.count,
        last_page,
      });
      setData(res.data.rows);

    } catch (error) {
      console.log(error);
      Globals.showError(t('common.globals.toast-error'));
    }
  }

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setPagination(initialForm);
    fetchData();
  }

  const prevPage = () => {
    if (pagination.page > 1) {
      setCanFetch(true);
      setPagination({ ...pagination, page: pagination.page + 1 });
    }
  }

  const nextPage = () => {
    if (pagination.page < pagination.last_page) {
      setCanFetch(true);
      setPagination({ ...pagination, page: pagination.page + 1 });
    }
  }

  const goToPage = (page: number) => {
    if (page >= 1 && page <= pagination.last_page && page !== pagination.page) {
      setCanFetch(true);
      setPagination({ ...pagination, page });
    }
  }

  const search = (search: string) => {
    if (debounce) clearTimeout(debounce);
    debounce = setTimeout(() => {
      setCanFetch(true);
      setPagination({ ...pagination, page: 1, search });
    }, debounceTime);
  }

  useEffect(() => {
    fetchData();
  }, [pagination]);

  return {
    categories: data,
    pagination,
    nextPage,
    prevPage,
    goToPage,
    reload,
    search,
  }
}

const Parts = () => {
  const { t } = useTranslation();

  const [modal, setModal] = useState(false);
  const { categories, pagination, reload, goToPage, search } = useFetch();
  const [selectedCategory, setSelectedCategory] = useState<IPartCategory>();

  const viewCategory = (category: IPartCategory) => {
    setSelectedCategory(category);
    setModal(true);
  }

  const deleteCategory = (category: IPartCategory) => {
    const doIt = async () => {
      try {
        const res: any = await PartService.delete({ id: category.id });
        if (!res) return Globals.showError();

        Globals.showSuccess(t('common.globals.category_deleted'));
        reload();

      } catch (error) {
        console.log(error);
        Globals.showError();
      }
    }

    Globals.confirm(t('common.globals.category-tech-confirm'), doIt);
  }

  const onCloseModal = (reloading: boolean) => {
    if (reloading) reload();
    if (!!selectedCategory) setSelectedCategory(undefined);
    setModal(false);
  }

  return (
    <div id="admin-parts">
      <Modal
        title="Agregar partes o repuestos"
        visible={modal}
        onClose={() => onCloseModal(false)}
      >
        <CreatePart category={selectedCategory} onClose={onCloseModal} />
      </Modal>

      <div className="search-bar">
        <div>
          <span className="input-label">{t('common.globals.category')}</span>
          <div className="search-filter" style={{ width: '40%' }}>
            <img src={Search} alt="Buscar"/>
            <input
              placeholder={t('common.globals.name') || ''}
              onChange={v => search(v.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="custom-table w-50 m-auto">
        <div className="header">
          <span className="title">{t('common.spare.data-registered')}</span>
          <div className="actions">
            <button className="btn--primary" onClick={() => setModal(true)}>
              <img src={Add} width={24} alt="" style={{ marginRight: 10 }} />
              <span>{t('common.globals.new_product')}</span>
            </button>
          </div>
        </div>

        {!!categories.length ? (
          <table>
            <thead>
              <tr>
                <th>{t('common.globals.name')}</th>
                <th className="right">{t('common.spare.actions')}</th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category, index) => (
                <tr key={`row-${index}`}>
                  <td>
                    <span className="text-blue cursor-pointer" onClick={() => viewCategory(category)}>
                      { category.name }
                    </span>
                  </td>
                  <td className="row-actions right">
                    <img
                      className="action-icon"
                      src={Trash}
                      alt=""
                      onClick={() => deleteCategory(category)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="no-items">{t('common.spare.not_data_found')}</div>
        )}
      </div>

      {!!categories.length && (
        <Pagination
          active={pagination.page}
          pages={pagination.last_page}
          onChange={goToPage}
        />
      )}
    </div>
  )
}

export default Parts;