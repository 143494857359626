export default {
    CHAT: {
        NEW_MESSAGE: 'chat/new-message',
        NEW_CHAT: 'chat/new-chat',
        VIEWED: 'chat/viewed',
    },
    TECH: {
        TECH_SEARCHER: 'tech/tech-searcher',
        TECH_ACCEPTED: 'tech/tech-accepted',
        TECH_EXPIRED: 'tech/tech-expired',
        TECH_VERIFIED: 'tech/tech-verified',
        TECH_CHANGE_STATUS: 'tech/tech-change-status',
        TECH_DISABLE: 'tech/disable'
    },
    CLIENT: {
        CLIENT_ANUAL_REQUEST: 'client/anual-request',
        CLIENT_PAYMENT: 'client/payment'
    },
    OPERATIONS: {
        OPERATION_UPDATE: 'operation/update',
        OPERATION_NEW_TECH: 'operation/new-tech',
        OPERATION_ACCEPTED_ORDER: 'operation/accepted-order'
    },
    LANG: {
        UPDATE: 'lang/update'
    }
}