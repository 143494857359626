/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { EventInput } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import momentPlugin from '@fullcalendar/moment';
import esLocale from '@fullcalendar/core/locales/es'
import enLocale from '@fullcalendar/core/locales/en-au';
import dayGridPlugin from '@fullcalendar/daygrid';
import moment from 'moment';
import { Modal } from 'components';
import AppointmentTime from './AppointmentTime';
import AppointmentDetails from './AppointmentDetails';
import { AppointmentsService, TechService } from 'services';
import { Constants, Globals, Socket, socketEvents } from 'utils';
import { UserNoPhoto } from 'assets/icons';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { on, emit } from 'jetemit';

const EVENT_TYPE = {
  ANNUAL: {
    ID: Constants.SCHEDULED_TYPES.ANUAL_MAINTENANCE,
    CLASS: 'annual-review',
  },
  SCHEDULED: {
    ID: Constants.SCHEDULED_TYPES.SCHEDULED_MAINTENANCE,
    CLASS: 'scheduled-appointment',
  },
  VISIT: {
    ID: Constants.SCHEDULED_TYPES.SCHEDULED_VISIT,
    CLASS: 'visit',
  },
}

const createLegend = () => {
  const parent = document.getElementsByClassName('fc-toolbar');
  const component = document.createElement('div');
  component.id = 'fc-legend';
  component.innerHTML = `<div><div class="fc-legend-1"></div><span>${i18n.t('common.operations.calendar.schedule_orders')}</div><div><div class="fc-legend-2"></div><span>${i18n.t('common.operations.calendar.annual_order')}</div>`;
  if (parent.item(0)?.insertAdjacentElement) {
    parent.item(0)?.insertAdjacentElement("afterend", component);
  }
}
// `<div><div class="fc-legend-1"></div><span>${i18n.t('common.operations.calendar.schedule_orders')}</div><div><div class="fc-legend-2"></div><span>${i18n.t('common.operations.calendar.annual_order')}</div>`
const ScheduledAppointments = () => {
  const { t } = useTranslation();

  const [events, setEvents] = useState<EventInput[]>([]);
  const [modalTime, setModalTime] = useState(false);
  const [modalDetails, setModalDetails] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string>();
  const [selectedEvent, setSelectedEvent] = useState<any>({});
  const [round, setRound] = useState<number>(0);
  const [dataSelected, setDataSelected] = useState<any>({});
  const [lang, setLang] = useState(i18n.language === 'en' ? enLocale : esLocale);
  //const [headerLang, setHeaderLang] = useState(createLegend())

  let requestExpire: any;

  const sendRequest = async () => {
    const techs: [] = await searcherTech(dataSelected?.client?.id);
    if (techs.length > 0) {
      Socket.emit(socketEvents.TECH.TECH_SEARCHER, {
        client_id: dataSelected?.client?.id,
        techs_ids: techs,
        type: dataSelected?.technical_service?.type_id,
        payment_method: dataSelected?.technical_service?.payment_method_id,
        order_id: dataSelected?.id,
        scheduled_form: {
          date: dataSelected?.date,
          equipment_id: dataSelected?.equipment_id,
          hour: moment(dataSelected?.dateWithHour).format('h'),
          minutes: moment(dataSelected?.dateWithHour).format('mm a'),
          property_id: dataSelected?.property_id,
          user_id: dataSelected?.client?.id
        }
      });
      requestExpire = setTimeout(() => {
        Socket.emit(socketEvents.TECH.TECH_EXPIRED, {
          techs_ids: techs,
          client_id: dataSelected?.client?.id,
          order_id: dataSelected?.id
        })
      }, 30000);
    }
  }

  const searcherTech = async (user_id: number) => {
    const res: any = await TechService.search_techs({ user_id });
    return res?.techs;
  }

  const setClassName = (status: number) => {
    switch (status) {
      case EVENT_TYPE.ANNUAL.ID:
        return EVENT_TYPE.ANNUAL.CLASS;
      default:
        return EVENT_TYPE.SCHEDULED.CLASS;
    }
  }

  const load = async () => {
    const res: any = await AppointmentsService.getAppointments();
    if (res?.data) {
      const format = res?.data.map((item: any) => ({
        ...item,
        originalDate: item.date,
        date: moment(item.date).format('YYYY-MM-DD'),
        dateWithHour: moment(item.date).format('YYYY-MM-DD h:mm a'),
        eventType: item.scheduled_type_id,
        className: setClassName(item.scheduled_type_id)
      }));
      setEvents(format)
    }
  }

  const loadSockets = () => {
    Socket.addEventListener(socketEvents.TECH.TECH_ACCEPTED, (accepted: any) => {
      const tech = accepted?.res?.tech;
      setDataSelected({
        ...dataSelected,
        tech
      });
      clearTimeout(requestExpire);
      setRound(0);
      Globals.showSuccess(t('common.operations.schedule.tech_accepted'));
    });

    Socket.addEventListener(socketEvents.TECH.TECH_EXPIRED, (expired: any) => {
      if (expired?.order_id === dataSelected?.id) {
        if (round < 1) {
          const newRound = round + 1;
          setRound(newRound);
          if (newRound < 2) {
            Globals.confirm(t('common.globals.try_again'), () => {
              sendRequest();
            })
          }
        } else {
          clearTimeout(requestExpire);
          setRound(0);
        }
      }
    });
  }

  useEffect(() => {
    load();
    loadSockets();

    on(socketEvents.LANG.UPDATE, async () => {
      setLang(i18n.language === 'en' ? enLocale : esLocale);
      const parent = document.getElementById('fc-legend');
      await parent?.remove();
    })

    return () => {
      Socket.removeEventListener(socketEvents.TECH.TECH_EXPIRED);
      Socket.removeEventListener(socketEvents.TECH.TECH_ACCEPTED);
    }
  }, [])

  const openDateTime = (event: any) => {
    setDataSelected(event);
    setSelectedEvent(event);
    setModalTime(true);
  }

  const openDetails = (event: any) => {
    setDataSelected(event);
    setSelectedEvent(event);
    setModalDetails(true);
  }

  const onCloseModal = (reloading = false) => {
    setModalTime(false);
    setModalDetails(false);
    setDataSelected(undefined);
    setSelectedEvent(undefined);
    // if (reloading) reload();
  }

  const formattedEvents = () => {
    let newEvents: any[] = [];

    events.forEach(e => {
      const sameDay = events.filter(x => x.date === e.date);
      const notSameType = sameDay.some(x => x.className !== e.className);
      const exists = newEvents.some(x => x.date === e.date);

      if (exists) return;
      if (notSameType) {
        e.className = 'mixed-event';
      }
      newEvents.push(e);
    });

    return newEvents;
  }

  const orangeEvents =
    events.filter(x => x.date === selectedDate && x.scheduled_type_id !== EVENT_TYPE.ANNUAL.ID);

  const greenEvents =
    events.filter(x => x.date === selectedDate && x.scheduled_type_id === EVENT_TYPE.ANNUAL.ID);

  return (
    <div id="admin-scheduled-appointments">
      <Modal
        title={t('common.operations.schedule.add_date')}
        visible={modalTime}
        onClose={() => onCloseModal(false)}
      >
        <AppointmentTime 
          onClose={() => {
            onCloseModal();
            load();
          }}
          order_id={selectedEvent?.id}
        />
      </Modal>

      <Modal
        title={`${selectedEvent?.client?.name} ${selectedEvent?.client?.lastname}`}
        size="lg"
        visible={modalDetails}
        onClose={() => onCloseModal(false)}
      >
        <AppointmentDetails
          eventType={selectedEvent?.eventType}
          data={selectedEvent}
          onClose={onCloseModal}
          onReload={() => load()}
        />
      </Modal>
      
      
      <section className="calendar">
        <div className="legend">
          <div className="legend-item orange"><div className="circle-orange"></div><span> {i18n.t('common.operations.calendar.schedule_orders')} </span></div>
          <div className="legend-item green"><div className="circle-green"></div><span> {i18n.t('common.operations.calendar.annual_order')} </span></div>
        </div>

        <FullCalendar
          plugins={[dayGridPlugin, momentPlugin]}
          initialView="dayGridMonth"
          locale={lang}
          titleFormat="MMMM YYYY"
          aspectRatio={1}
          headerToolbar={{ end: 'prev next' }}
          firstDay={0}
          //viewDidMount={() => headerLang}
          events={formattedEvents()}
          eventClick={event => {
            const date = moment(event.event.start).format('YYYY-MM-DD');
            setSelectedDate(date);
          }}
        />
      </section>

      <section className="appointments">
        {!!orangeEvents.length && (
          <div className="card scheduled">
            <div className="header">
              <div>{t('common.operations.schedule.schedule_title')}</div>
              <div className="date">{moment(selectedDate).format('DD-MM-YYYY')}</div>
            </div>
            <ul>
              {orangeEvents.map((event: any, index) => {

                const photo = event?.client?.person?.photo;
                const showPhoto = photo ? Globals.fromPhotos(photo) : UserNoPhoto;

                return (
                  <li key={`scheduled-${index}`}>
                    <div className="profile">
                      <img src={showPhoto} alt="Avatar" />
                      <div className="details">
                        <div className="name">{`${event?.client?.name} ${event?.client?.lastname}`}</div>
                        <div className="level">{event?.client?.level?.name}</div>
                      </div>
                    </div>
                    <div className="right-action">
                      <span onClick={() => openDetails(event)}>{t('common.operations.schedule.view_order')}</span>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        )}

        {!!greenEvents.length && (
          <div className="card review">
            <div className="header">
              <div>{t('common.operations.schedule.annual_order')}</div>
              <div className="date">{moment(selectedDate).format('DD-MM-YYYY')}</div>
            </div>
            <ul>
              {greenEvents.map((event: any, index) => {

                const photo = event?.client?.person?.photo;
                const showPhoto = photo ? Globals.fromPhotos(photo) : UserNoPhoto;

                return (
                  <li key={`review-${index}`}>
                    <div className="sup">
                      <div className="profile">
                        <img src={showPhoto} alt="Avatar" />
                        <div className="details">
                          <div className="name">{`${event?.client?.name} ${event?.client?.lastname}`}</div>
                          <div className="level">{event?.client?.level?.name}</div>
                        </div>
                      </div>
                      <div className="right-action">
                        <span onClick={() => openDetails(event)}>{t('common.globals.see')}</span>
                      </div>
                    </div>
                    <div className="sub">
                      <button onClick={() => openDateTime(event)}>
                        {moment(event?.originalDate).format('DD-MM-YYYY h:mm a')}
                      </button>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        )}
      </section>
    </div>
  )
}

export default ScheduledAppointments