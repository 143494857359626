/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Input } from 'components';
import { Trash } from 'assets/icons';
import { IPartCategory } from 'types';
import { Globals } from 'utils';
import { PartService } from 'services';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

interface FormProps {
  id: number | undefined
  categoryName: string,
  subcategoryName: string,
  cost: string,
}

interface ISubcategoryItem {
  name: string
  cost: number
}

interface CreatePartCategoryProps {
  category?: IPartCategory
  onClose: (reloading: boolean) => void
}

const CreatePart = ({ category, onClose }: CreatePartCategoryProps) => {
  const { t } = useTranslation();

  const initialForm: FormProps = {
    id: undefined,
    categoryName: '',
    subcategoryName: '',
    cost: '',
  };

  const [formData, setFormData] = useState(initialForm);
  const [subcategories, setSubcategories] = useState<ISubcategoryItem[]>([]);

  useEffect(() => {
    if (!!category) {
      setFormData(s => ({
        ...s,
        id: category.id,
        categoryName: category.name,
      }));
      setSubcategories(category.spare_subcategories);
    }
  }, []);

  const onChange = (target: keyof typeof formData, value: string) => {
    setFormData((s: any) => ({ ...s, [target]: value }))
  }

  const addSubcategory = () => {
    if (!formData.subcategoryName || !formData.cost) return;

    const newItem = {
      name: formData.subcategoryName,
      cost: Number(formData.cost),
    }
    setSubcategories([ ...subcategories, newItem ]);
    onChange('subcategoryName', '');
    onChange('cost', '');
  }

  const deleteSubcategory = (index: number) => {
    const others = subcategories.filter((_, idx) => idx !== index);
    setSubcategories(others);
  }

  const submit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!formData.categoryName)
      return Globals.showError(t('common.spare.form.category_name'));

    const data = {
      id: formData.id,
      name: formData.categoryName,
      subcategories,
    };

    try {
      const res: any = !!formData.id
        ? await PartService.update(data)
        : await PartService.create(data);

      if (!res?.sparepart || res?.error)
        return Globals.showError(res?.error); 

    } catch (error) {
      console.log(error);
      Globals.showError();
    }

    onClose(true);
  }

  return (
    <div id="admin-parts-modal-create-part">
      <form onSubmit={submit} className="content">
        <div className="wrapped">
          <div className="prop col col-6">
            <Input
              label={t('common.globals.category') || ''}
              value={formData.categoryName}
              onChange={v => onChange('categoryName', v)}
            />
          </div>
        </div>

        <div className="divider" />

        <div className="form--group">
          <div style={{ flex: 5 }}>
            <Input
              label={t('common.globals.subcategory') || ''}
              value={formData.subcategoryName}
              onChange={v => onChange('subcategoryName', v)}
            />
          </div>
          <div style={{ flex: 2 }}>
            <Input
              className="text-right"
              type="number"
              label={t('common.globals.cost') || ''}
              value={formData.cost}
              onChange={v => onChange('cost', v)}
              preppend={<span>$</span>}
            />
          </div>
          <div style={{ paddingBottom: '1rem', display: 'flex', alignItems: 'end', flex: 3 }}>
            <button
              type="button"
              className="btn--primary"
              onClick={addSubcategory}
              style={{ width: '100%' }}
            >
              <span>{t('common.globals.add') || ''}</span>
            </button>
          </div>
        </div>

        {!!subcategories.length && (
          <div style={{ maxHeight: 200, overflowY: 'auto' }}>
            <div className="wrapped">
              <table>
                <thead>
                  <tr>
                    <th>{t('common.globals.subcategory') || ''}</th>
                    <th>{t('common.globals.cost') || ''}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {subcategories.map((x, index) => (
                    <tr key={`subcategories-${index}`}>
                      <td>{ x.name }</td>
                      <td>${ x.cost }</td>
                      <td className="row-actions">
                        <img
                          className="action-icon"
                          src={Trash}
                          alt=""
                          onClick={() => deleteSubcategory(index)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <div className="flex-center actions">
          <button className="form--submit-button" type="submit">
            <span>{t('common.globals.save') || ''}</span>
          </button>
        </div>
      </form>
    </div>
  )
}

export default CreatePart;