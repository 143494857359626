/* eslint-disable react-hooks/exhaustive-deps */
import { Input } from 'components';
import React, { useEffect, useState } from 'react';
import { Globals } from 'utils';
import { ChatService } from 'services';
import { Socket, socketEvents } from 'utils';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

// interface FormProps extends Omit<IServiceType, 'id'> {
//   id: number | undefined
// }

interface CreateChatProps {
    onClose: (reloading: boolean) => void
    user: any
}

interface IForm {
    id?: number | undefined,
    name: string,
}

const initialForm: IForm = {
    name: ''
};

interface NewChatProps {
    sender_id: number
    receiver_id: number
}

const useFetch = () => {
    const [users, setUsers] = useState<[]>([]);

    const load = async ({ user }: any) => {
        const data: any = await ChatService.getUsers({
            level_id: user?.level_id
        });
        setUsers(data?.users);
    }

    return {
        load,
        users,
        setUsers
    }
}

const CreateChat = ({ onClose, user }: CreateChatProps) => {
    const { t } = useTranslation();
    const {
        load,
        users,
        setUsers
    } = useFetch();

    const [formData, setFormData] = useState<IForm>(initialForm);
    const [selectedChat, setSelectedChat] = useState<number>();

    useEffect(() => {
        load({ user })
    }, [])

    const onChange = (target: keyof typeof formData, value: string) => {
        setFormData(s => ({ ...s, [target]: value }))
    }

    const isValidForm = (): boolean => {
        const onError = (msg: string) => {
            Globals.showError(msg);
            return false;
        }

        return true;
    }

    const submit = async () => {
        if (!isValidForm()) return;

        try {
            if (formData.name !== '' && formData.name.length !== 1) {
                const data: any = await ChatService.getUsers({
                    level_id: user?.level_id,
                    search_user: formData.name,
                    withoutLoading: true
                });
                setUsers(data?.users);
            } else {
                const data: any = await ChatService.getUsers({
                    level_id: user?.level_id,
                    withoutLoading: true
                });
                setUsers(data?.users);
            }
        } catch (error) {
            console.log(error);
            Globals.showError();
        }
    }

    const create_chat = ({ sender_id, receiver_id }: NewChatProps) => {
        ChatService.newChat({ receiver_id, sender_id })
            .then(() => {
                Globals.showSuccess(t('common.chat.chat_created'));
                Socket.emit(socketEvents.CHAT.NEW_CHAT);
                onClose(true)
            })
            .catch(() => Globals.showError(t('common.chat.error_chat')))
    }

    return (
        <form onSubmit={submit} id="admin-services-modal-create-service">
            <div className="form--group">
                <div>
                    <Input
                        label={t('common.globals.search_user') || ''}
                        value={formData.name}
                        onChange={async v => {
                            await onChange('name', v);
                            submit();
                        }}
                    />
                </div>
            </div>
            <div id='create-chat'>
                <div className="list">
                    {users.map((value: any, index: number) => {
                        return <ListItem
                            key={`list-item-chat-${index}`}
                            item={value}
                            isSelected={selectedChat === value?.id}
                            onClick={() => {
                                setSelectedChat(value?.id);
                                Globals.confirm(`¿${t('common.chat.chat_confirm')} ${value?.name} ${value?.lastname}?`, () => create_chat({ sender_id: user.id, receiver_id: value?.id }));
                            }}
                        />
                    })}
                </div>
            </div>
        </form>
    )
}
const ListItem = (props: any) => {

    const isSelected = props.isSelected ? ' selected' : '';

    const photo = props.item.person?.photo;
    const showPhoto = photo ? Globals.fromPhotos(photo) : Globals.fromPhotos('user.png');

    return (
        <div className={`list-item${isSelected}`} onClick={() => props.onClick()}>
            <div className="photo">
                <img
                    src={showPhoto}
                    alt='Avatar'
                />
            </div>
            <div className="info">
                <div className="group">
                    <div className="name">{`${props.item?.name} ${props.item?.lastname}`}</div>
                </div>
            </div>
        </div>
    );
}

export default CreateChat;