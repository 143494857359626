import { Report, Star } from 'assets/icons';
import React, { useState, useEffect } from 'react';
import { TechService } from 'services';
import { ITechnician } from 'types';
import { Constants, Globals, Socket, socketEvents } from 'utils';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

interface Props {
  user?: ITechnician
  onClose: (reloading: boolean) => void
}

const UserDetails = ({ user, onClose }: Props) => {

  const { t } = useTranslation();

  const [rating, setRating] = useState();
  const [stars, setStars] = useState<any>([]);

  const updateStatus = async (status: number) => {
    try {
      const res: any = await TechService.verified({ id: user?.id, verified: status });
      if (!res?.user) return Globals.showError();

      Socket.emit(socketEvents.TECH.TECH_VERIFIED, { user_id: user?.id, verified: status });

      Globals.showSuccess(t('common.globals.tech_edited_successfully'));
      onClose(true);

    } catch (error) {
      console.log(error);
      Globals.showError();
    }
  }

  const load = async () => {
    const rating: any = await TechService.getAverageRating({ reviewed_id: user?.id });
    setRating(rating?.data);

    const stars = [];
    for (let i = 0; i < Math.floor(user?.person?.calification || 0); i++) {
      stars.push(i);
    }
    setStars(stars)
  }

  useEffect(() => {
    load()
  }, []);

  const downloadFile = (url: string | null) => {
    if (!url) return;
    window.open(Globals.fromFiles(url));
  }

  if (!user) return (
    <div className="flex-center">
      <h5>{t('common.globals.tech_select')}</h5>
    </div>
  )

  return (
    <div id="admin-tecnicians-modal-user-details">
      <div className="content">
        <div className="left">
          <div>
            {user.person.photo ? (
              <div className="photo">
                <img src={Globals.fromPhotos(user.person.photo)} alt="" />
              </div>
            ) : (
              <div className="no-image" />
            )}
            <div className="name">
              { user.name } { user.lastname }
            </div>
          </div>
        </div>
        <div className="right">
          <div className="prop col col-6">
            <div className="label">{t('common.globals.username')}</div>
            <div>{ user.username }</div>
            {
              stars.map((item: any, index: number) => {
                return (
                  <img key={`row-${index}`} src={Star} alt="Rating" style={{ width: '25px', height: '25px' }} />
                )
              })
            }
          </div>
          <div className="prop col col-6">
            <div className="label">{t('common.tech.email')}</div>
            <div>{ user.email }</div>
          </div>
          <div className="prop col col-6">
            <div className="label">{t('common.tech.phone')}</div>
            <div>{ user.person.phone }</div>
          </div>
          {/* <div className="prop col col-6">
            <div className="label">DNI / Social Security Number</div>
            <div>{ user.person.dni }</div>
          </div> */}
          {/* <div className="prop col col-12">
            <div className="label">{t('common.tech.address')}</div>
            <div>{ user.person.address }</div>
          </div> */}

          {/* <div className="prop col col-6">
            <div className="label">{t('common.tech.work_experience')}</div>
            <button className="pdf-button" onClick={() => downloadFile(user.person.cv)}>
              <img src={Report} alt="CV PDF" />
              <span>{
                !!user.person.cv
                  ? `C.V ${ user.name } ${ user.lastname }.PDF`
                  : t('common.globals.undefined')
              }</span>
            </button>
          </div>

          <div className="prop col col-6">
            <div className="label">{t('common.tech.criminal_log')}</div>
            <button className="pdf-button" onClick={() => downloadFile(user.person.criminal_record)}>
              <img src={Report} alt="" />
              <span>{ !!user.person.criminal_record ? 'RECORD CRIMINAL.PDF' : t('common.globals.undefined') }</span>
            </button>
          </div>

          <div className="prop col col-6">
            <div className="label">{t('common.tech.drugs_test')}</div>
            <button className="pdf-button" onClick={() => downloadFile(user.person.alcohol_drugs)}>
              <img src={Report} alt="F" />
              <span>{ !!user.person.alcohol_drugs ? 'PRUEBA.PDF' : t('common.globals.undefined') }</span>
            </button>
          </div> */}

          {/* <div className="prop col col-12 divider">
            <div className="section-label">{t('common.globals.bank.bank_data')}</div>
          </div>

          <div className="prop col col-6">
            <div className="label">{t('common.globals.property.property_data')}</div>
            <div>{ user?.UserBank[0]?.name }</div>
          </div>

          <div className="prop col col-6">
            <div className="label">{t('common.globals.bank.name')}</div>
            <div>{ user?.UserBank[0]?.bank_name }</div>
          </div>

          <div className="prop col col-6">
            <div className="label">{t('common.globals.bank.account_number')}</div>
            <div>{ user?.UserBank[0]?.number }</div>
          </div>

          <div className="prop col col-6">
            <div className="label">{t('common.globals.bank.account_type_name')}</div>
            <div>{ Globals.getBankAccountType(user?.UserBank[0]?.type) }</div>
          </div>

          <div className="prop col col-6">
            <div className="label">DNI</div>
            <div>{ user?.UserBank[0]?.dni }</div>
          </div> */}

        </div>
      </div>

      {user?.verified === Constants.USER.VERIFIED.PENDING && (
        <div className="actions">
          <button
            className="form--submit-button"
            onClick={() => updateStatus(Constants.USER.VERIFIED.APPROVED)}
          >
            <span>{t('common.globals.approved')}</span>
          </button>

          <button
            className="form--submit-button bg-red"
            onClick={() => updateStatus(Constants.USER.VERIFIED.REJECTED)}
          >
            <span>{t('common.globals.rejected')}</span>
          </button>
        </div>
      )}
    </div>
  )
}

export default UserDetails;