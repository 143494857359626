import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Input } from 'components';
import { LogoColor } from 'assets/img';
import { AuthService } from 'services';
import { Globals } from 'utils';
import * as actions from 'actions';
import { useTranslation } from 'react-i18next';

const Login = () => {

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const { t } = useTranslation();

  const onChange = (target: keyof typeof formData, value: string) => {
    setFormData(s => ({ ...s, [target]: value }))
  }

  const isValidForm = (): boolean => {
    const onError = (msg: string) => {
      Globals.showError(msg);
      return false;
    }

    if (!formData.email)
      return onError('El correo es obligatorio');

    if (!formData.password)
      return onError('La contraseña es obligatoria');

    return true;
  }

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isValidForm()) return;

    try {
      const res: any = await AuthService.login(formData);

      if (res?.error) return Globals.showError(res.error);
      if (!res?.user) return Globals.showError('Ocurrió un error inesperado');

      dispatch(actions.setLang({ lang: res.user.user?.lang || 'en' }))

      setTimeout(() => {
        dispatch(actions.setUser({
          ...res.user.user,
          token: res.user.token,
        }));
      }, 1000)

    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div id="login">
      <div className="form-wrapper">
        <div className="logo">
          <img src={LogoColor} alt="Logo" />
        </div>

        <form onSubmit={submit}>
          <Input
            placeholder={t('common.login.email')}
            type="email"
            value={formData.email}
            onChange={v => onChange('email', v)}
          />

          <Input
            placeholder={t('common.login.password')}
            type="password"
            value={formData.password}
            onChange={v => onChange('password', v)}
          />

          <Link to="/recover/email" className="forgot">
            <span>{t('common.login.remember')}</span>
          </Link>

          <div className="submit">
            <button className="btn--primary" type="submit">
              <span>{t('common.login.login')}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login;