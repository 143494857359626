/* eslint-disable react-hooks/exhaustive-deps */
import { Input } from 'components';
import React, { useEffect, useState } from 'react';
import { Globals } from 'utils';
import { ServiceService } from 'services';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

interface CreateServiceProps {
  service?: any
  onClose: (reloading: boolean) => void
}

interface IForm {
  id?: number | undefined,
  name: string,
}

const initialForm: IForm = {
  name: ''
};

const CreateService = ({ service, onClose }: CreateServiceProps) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<IForm>(initialForm);

  useEffect(() => {
    if (!!service) setFormData(service);
  }, []);

  const onChange = (target: keyof typeof formData, value: string) => {
    setFormData(s => ({ ...s, [target]: value }))
  }

  const isValidForm = (): boolean => {
    const onError = (msg: string) => {
      Globals.showError(msg);
      return false;
    }

    if (!formData.name)
      return onError(t('common.service.form.category_type'));

    return true;
  }

  const submit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!isValidForm()) return;

    try {
      const res: any = !!formData.id
        ? await ServiceService.update(formData)
        : await ServiceService.create(formData);

      if (res?.error) return Globals.showError(res.error);
      if (!res?.service) return Globals.showError();

      Globals.showSuccess(`${t('common.globals.service')} ${!!formData.id ? t('common.globals.added') : t('common.globals.registered')}`);
      onClose(true);

    } catch (error) {
      console.log(error);
      Globals.showError();
    }
  }

  useEffect(() => {
    if (service) setFormData({
      ...formData,
      id: service.id,
      name: service.name,
    });
  }, []);

  return (
    <form onSubmit={submit} id="admin-services-modal-create-service">
      <div className="form--group">
        <div>
          <Input
            label={t('common.globals.category') || ''}
            value={formData.name}
            onChange={v => onChange('name', v)}
          />
        </div>
      </div>

      <div className="flex-center actions">
        <button className="form--submit-button">
          <span>{t('common.globals.save')}</span>
        </button>
      </div>
    </form>
  )
}

export default CreateService;