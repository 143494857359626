/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Input } from 'components';
import { Lock } from 'assets/icons';
import { Constants, Globals } from 'utils';
import { UserService } from 'services';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

interface CreateUserProps {
  user?: any
  onClose: (reloading: boolean) => void
}

interface IForm {
  id?: number,
  name: string,
  lastname: string,
  email: string,
  username: string,
  password: string,
  confirmPassword: string,
  level_id: number,
}

const initialForm: IForm = {
  name: '',
  lastname: '',
  email: '',
  username: '',
  password: '',
  confirmPassword: '',
  level_id: Constants.USER.LEVEL.ADMIN,
};

const CreateUser = ({ user, onClose }: CreateUserProps) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<IForm>(initialForm);

  const onChange = (target: keyof typeof formData, value: string) => {
    setFormData(s => ({ ...s, [target]: value }))
  }

  const isValidForm = (): boolean => {
    const onError = (msg: string) => {
      Globals.showError(msg);
      return false;
    }

    if (!formData.name)
      return onError(t('common.form.name'));

    if (!formData.email)
      return onError(t('common.form.email'));

    if (!formData.username)
      return onError(t('common.form.username'));

    if (!user || (!!user && (formData.password || formData.confirmPassword)) ) {
      if (!formData.password)
        return onError(t('common.form.password'));

      if (!formData.confirmPassword)
        return onError(t('common.form.confirm_password'));

      if (formData.password !== formData.confirmPassword)
        return onError(t('common.form.not_same_password'));
    }

    return true;
  }

  const submit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!isValidForm()) return;

    try {
      const res: any = !!formData.id
        ? await UserService.update(formData)
        : await UserService.create(formData);

      if (res?.error) return Globals.showError(res.error);
      if (!res?.user) return Globals.showError();

      Globals.showSuccess(`${t('common.globals.user')} ${!!formData.id ? t('common.globals.edited') : t('common.globals.added')}`);
      onClose(true);

    } catch (error) {
      console.log(error);
      Globals.showError();
    }
  }

  useEffect(() => {
    if (user) setFormData({
      ...formData,
      id: user.id,
      name: user.name,
      lastname: user.lastname,
      email: user.email,
      username: user.username,
      level_id: user.level_id,
    });
  }, []);


  return (
    <form onSubmit={submit}>
      <div className="form--group">
        <Input
          label={t('common.users.name') || ''}
          value={formData.name}
          onChange={v => onChange('name', v)}
        />
        <Input
          label={t('common.users.email') || ''}
          value={formData.email}
          onChange={v => onChange('email', v)}
        />
      </div>
      <div className="form--group">
        <Input
          label={t('common.globals.username') || ''}
          value={formData.username}
          onChange={v => onChange('username', v)}
        />
        <Input
          label={t('common.globals.password') || ''}
          type="password"
          value={formData.password}
          onChange={v => onChange('password', v)}
          leftIcon={Lock}
        />
      </div>
      <div className="form--group">
        <Input
          label={t('common.globals.confirm_password') || ''}
          value={formData.confirmPassword}
          type="password"
          onChange={v => onChange('confirmPassword', v)}
          leftIcon={Lock}
        />
        <div style={{ flex: 1 }}></div>
      </div>

      <div className="flex-center actions">
        <button className="form--submit-button">
          <span>{ t('common.globals.save') }</span>
        </button>
      </div>
    </form>
  )
}

export default CreateUser;