import React from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import {
  HashRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import "moment/locale/es";
import { store, persistor } from "./store";
import { RootState } from "reducers";
import * as actions from "actions";
import { Constants } from "utils";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

// Admin
import AdminLayout from "screens/admin/layout";
import AdminUsers from "screens/admin/users/users";
import AdminTechnicians from "screens/admin/technicians/Technicians";
import AdminCustomers from "screens/admin/customers/Customers";
import AdminServices from "screens/admin/services/Services";
import AdminOperarions from "screens/admin/operations/Operations";
import AdminParts from "screens/admin/parts/Parts";
import AdminCommissionsServices from "screens/admin/commissions-services/CommissionsServices";
import AdminScheduledAppointments from 'screens/admin/scheduled-appointments/ScheduledAppointments'
import AdminVisitCommission from 'screens/admin/visit-commission/VisitCommission';
import AdminPoster from 'screens/admin/poster/Poster';
import AdminQuotes from 'screens/admin/quotes/Quote';

// Auth
import Login from 'screens/auth/login/Login';
import RecoverEmail from "screens/auth/recover/RecoverEmail";
import RecoverCode from "screens/auth/recover/RecoverCode"
import RecoverPassword from "screens/auth/recover/RecoverPassword"
import ConfirmedEmail from "screens/auth/confirmEmail/ConfirmedEmail";

// Chat
import Chat from "screens/chat/Chat";

// Scripts
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";

// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./scss/main.scss";

const Container = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const lang = useSelector((state: RootState) => state.language);

  if (lang.lang) {
    i18n.changeLanguage(lang.lang);
  }

  if (user?.level_id === Constants.USER.LEVEL.ADMIN) return (
    <AdminLayout>
      <Switch>
        <Route path="/users" component={ AdminUsers } />
        <Route path="/technicians" component={ AdminTechnicians } />
        <Route path="/customers" component={ AdminCustomers } />
        <Route path="/services" component={ AdminServices } />
        <Route path="/operations" component={ AdminOperarions } />
        <Route path="/parts" component={ AdminParts } />
        <Route path="/commissions-services" component={ AdminCommissionsServices } />
        <Route path="/scheduled-appointments" component={ AdminScheduledAppointments } />
        <Route path="/visit-commission" component={ AdminVisitCommission } />
        <Route path="/chat" component={ Chat } />
        <Route path="/poster" component={ AdminPoster } />
        <Route path="/quotes" component={ AdminQuotes } />
        <Redirect to="/users" />
      </Switch>
    </AdminLayout>
  );

  // TODO: Otros entornos aquí...

  // Si no tiene un entorno definido se cierra la sessión
  if (user?.id) dispatch(actions.logout());

  return (
    <Switch>
      <Route exact path="/login" component={ Login } />
      <Route exact path="/recover/email" component={ RecoverEmail } />
      <Route exact path="/recover/code" component={ RecoverCode } />
      <Route exact path="/recover/password/:code" component={ RecoverPassword } />
      <Route exact path="/confirmedemail" component={ ConfirmedEmail } />
      <Redirect to="/login" />
    </Switch>
  );
}

const App = () => (
  <Provider store={ store }>
    <PersistGate loading={ null } persistor={ persistor }>
      <Router>
        <Container />
      </Router>
    </PersistGate>
  </Provider>
);

export default App;
