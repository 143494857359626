import { Api } from 'utils';

const API = `chat/`;

class ChatService {

	static getChats = async (params = {}) => Api.createResponse(`${API}getChats`,params);
	static newChat = async (params = {}) => Api.createResponse(`${API}newChat`,params);
	static newMessage = async (params = {}) => Api.createResponse(`${API}newMessage`,params);
	static getLogs = async (params = {}) => Api.createResponse(`${API}getLogs`,params);
	static delete = async (params = {}) => Api.createResponse(`${API}delete`,params);
	static viewed = async (params = {}) => Api.createResponse(`${API}viewed`,params);
    static getUsers = async (params = {}) => Api.createResponse(`${API}getUsers`,params)
}

export default ChatService;