import { Api } from 'utils';

class CientService {

	static get = async (params = {}) => Api.createResponse('clients/get',params);
	static view = async (params = {}) => Api.createResponse('clients/view',params);
	static create = async (params = {}) => Api.createResponse('clients/create',params);
	static update = async (params = {}) => Api.createResponse('clients/update',params);
	static delete = async (params = {}) => Api.createResponse('clients/destroy',params);
	static changeStatus = async (params = {}) => Api.createResponse('clients/changestatus',params);

	static service = {
		getLastService: async (params = {}) => Api.createResponse('clients/getLastService',params)
	}
}

export default CientService;