import React, { useEffect, useState } from 'react';
import { EventInput } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import momentPlugin from '@fullcalendar/moment';
import esLocale from '@fullcalendar/core/locales/es'
import dayGridPlugin from '@fullcalendar/daygrid';
import moment from 'moment';
import { CommissionService } from 'services';
import { Globals } from 'utils';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const useFetch = () => {
  const { t } = useTranslation();
  const [commission, setCommission] = useState();
  const [percentage, setPercentage] = useState();

  const load = async () => {
    const res: any = await CommissionService.get();
    setCommission(res?.data?.amount)
    setPercentage(res?.data?.tech_percentage)
  }

  const updateCommission = (value: any) => {
    setCommission(value);
  }

  const updatePercentage = (value: any) => {
    setPercentage(value);
  }

  const submit = () => {
    CommissionService.update({ amount: Number(commission), tech_percentage: Number(percentage) }).then(() => {
      Globals.showSuccess(t('common.operations.amount_changed'));
    })
      .catch(() => Globals.showError(t('common.globals.toast-error')))
  }

  return {
    load,
    submit,
    commission,
    setCommission,
    updateCommission,
    updatePercentage,
    percentage
  }
}

const VisitCommission = () => {
  const { t } = useTranslation();
  const {
    load,
    commission,
    setCommission,
    updateCommission,
    submit,
    updatePercentage,
    percentage
  } = useFetch()

  useEffect(() => {
    load()
  }, [])

  return (
    <div id="admin-customers">
      <div className="search-bar">
        <div>
          <span className="input-label">{t('common.operations.amount_cost')}</span>
          <div className="search-filter" style={{ width: '100%' }}>
            <input
              placeholder={t('common.operations.cost_label') || ''}
              onChange={v => updateCommission(v.target.value)}
              style={{ padding: '20px' }}
              value={commission}
            />
          </div>
          <div className="search-filter" style={{ width: '100%' }}>
            <input
              placeholder={t('common.operations.percentage') || ''}
              onChange={v => updatePercentage(v.target.value)}
              style={{ padding: '20px' }}
              value={percentage}
            />
          </div>
          <button className="btn--primary" style={{ width: '100%', marginTop: '20px' }} onClick={submit}>
            <span>{t('common.operations.update_cost')}</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default VisitCommission