/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { TechService } from 'services';
import { ITechnician } from 'types';
import { Constants, Globals, Socket, socketEvents } from 'utils';
import { Search, Trash } from '../../../assets/icons';
import { Modal, Pagination } from '../../../components';
import UserDetails from './UserDetails';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const useFetch = () => {
  const { t } = useTranslation();
  const initialForm = {
    search: '',
    page: 1,
    per_page: 20,
    last_page: 1,
    item_count: 0,
  };

  const [data, setData] = useState<ITechnician[]>([]);
  const [canFetch, setCanFetch] = useState(true);
  const [pagination, setPagination] = useState(initialForm);

  const debounceTime = 500;
  let debounce: any = null;

  const fetchData = async () => {
    if (!canFetch) return;

    try {
      const res: any = await TechService.get({
        page: pagination.page,
        per_page: pagination.per_page,
        search: pagination.search,
      });

      if (!res?.data?.users?.rows)
        return Globals.showError(t('common.globals.toast-error'));

      const last_page = Math.ceil((res?.data?.users?.count || 0) / pagination.per_page);
      setCanFetch(false);
      setPagination({
        ...pagination,
        item_count: res?.data?.users?.count,
        last_page,
      });
      setData(res.data.users.rows);

    } catch (error) {
      console.log(error);
      Globals.showError(t('common.globals.toast-error'));
    }
  }

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setPagination(initialForm);
    fetchData();
  }

  const prevPage = () => {
    if (pagination.page > 1) {
      setCanFetch(true);
      setPagination({ ...pagination, page: pagination.page + 1 });
    }
  }

  const nextPage = () => {
    if (pagination.page < pagination.last_page) {
      setCanFetch(true);
      setPagination({ ...pagination, page: pagination.page + 1 });
    }
  }

  const goToPage = (page: number) => {
    if (page >= 1 && page <= pagination.last_page && page !== pagination.page) {
      setCanFetch(true);
      setPagination({ ...pagination, page });
    }
  }

  const search = (search: string) => {
    if (debounce) clearTimeout(debounce);
    debounce = setTimeout(() => {
      setCanFetch(true);
      setPagination({ ...pagination, page: 1, search });
    }, debounceTime);
  }

  const loadSockets = () => {
    Socket.addEventListener(socketEvents.TECH.TECH_CHANGE_STATUS, () => reload(true));
  }

  useEffect(() => {
    fetchData();
    loadSockets();
  }, [pagination]);

  return {
    users: data,
    pagination,
    nextPage,
    prevPage,
    goToPage,
    reload,
    search,
  }
}

const Technicians = () => {
  const { t } = useTranslation();

  const [modal, setModal] = useState(false);
  const { users, pagination, reload, goToPage, search } = useFetch();
  const [selectedUser, setSelectedUser] = useState<ITechnician>();

  const viewUser = (user: ITechnician) => {
    setSelectedUser(user);
    setModal(true);
  }

  const deleteUser = (user: ITechnician) => {
    const doIt = async () => {
      try {
        const res: any = await TechService.delete({ id: user.id });
        if (!res?.user) return Globals.showError();

        Globals.showSuccess(t('common.globals.tech_deleted'));
        reload();

      } catch (error) {
        console.log(error);
        Globals.showError();
      }
    }

    Globals.confirm(t('common.globals.deleted-tech-confirm'), doIt);
  }

  const toogleStatus = async (user: ITechnician) => {
    try {
      let newStatus;
      switch (Number(user.status)) {
        case Constants.USER.STATUS.INACTIVE:
          newStatus = Constants.USER.STATUS.ACTIVE
        break;
        case Constants.USER.STATUS.DISABLED:
          newStatus = Constants.USER.STATUS.ACTIVE
        break;
        case Constants.USER.STATUS.ACTIVE:
          newStatus = Constants.USER.STATUS.DISABLED;
        break;
      }
      const res: any = await TechService.changeStatus({ id: user.id, status: newStatus });
      if (!res) return Globals.showError();
      
      //if (newStatus === Constants.USER.STATUS.DISABLED) {
        Socket.emit(socketEvents.TECH.TECH_DISABLE, { tech_id: user.id, status: newStatus });
      //}

      Globals.showSuccess(t('common.globals.tech_edited_successfully'));
      reload();

    } catch (error) {
      console.log(error);
      Globals.showError();
    }
  }

  const onCloseModal = (reloading: boolean = false) => {
    if (reloading) reload();
    setModal(false);
  }

  return (
    <div id="admin-technicians">
      <Modal size="lg" visible={modal} onClose={() => onCloseModal(false)}>
        <UserDetails user={selectedUser} onClose={onCloseModal} />
      </Modal>

      <div className="search-bar">
        <div>
          <span className="input-label">{t('common.globals.search')}</span>
          <div className="search-filter" style={{ width: '40%' }}>
            <img src={Search} alt="Buscar"/>
            <input
              placeholder={t('common.globals.search_by_name') || ''}
              onChange={v => search(v.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="custom-table">
        <div className="header">
          <span className="title">{t('common.tech.tech-registered')}</span>
        </div>

        {!!users.length ? (
          <table>
            <thead>
              <tr>
                <th>{t('common.tech.name')}</th>
                <th>{t('common.tech.phone')}</th>
                <th>{t('common.tech.email')}</th>
                <th>{t('common.tech.user')}</th>
                <th>{t('common.tech.request')}</th>
                <th>{t('common.tech.rate')}</th>
                <th>{t('common.tech.status')}</th>
                <th>{t('common.tech.actions')}</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={`row-${index}`}>
                  <td>
                    <span className="text-blue cursor-pointer" onClick={() => viewUser(user)}>
                      { user.name } { user.lastname}
                    </span>
                  </td>
                  <td>{ user.person.phone }</td>
                  <td className="text-break" style={{ minWidth: '170px' }}>{ user.email }</td>
                  <td>{ user.username }</td>
                  <td>
                    {user.verified === Constants.USER.VERIFIED.PENDING && (
                      <span className="text-blue">{t('common.globals.user_status.pending')}</span>
                    )}
                    {user.verified === Constants.USER.VERIFIED.APPROVED && (
                      <span className="text-success">{t('common.globals.user_status.approved')}</span>
                    )}
                    {user.verified === Constants.USER.VERIFIED.REJECTED && (
                      <span className="text-danger">{t('common.globals.user_status.rejected')}</span>
                    )}
                  </td>
                  <td>
                    <div className="stars">
                      {[ ...Array(Math.round(user.person.calification || 0)) ].map((x, i) => {
                        return i + 1 > (user.person.calification || 0)
                          ? <i key={`star-${user.id}-${i}`} className="fa fa-star-half"></i>
                          : <i key={`star-${user.id}-${i}`} className="fa fa-star"></i>
                      })}
                    </div>
                  </td>
                  <td>
                    {user.status_order === Constants.USER.STATUS.ACTIVE
                      ? t('common.globals.user_status_order.on_service')
                      : t('common.globals.user_status_order.occupied')
                    }
                  </td>
                  <td className="row-actions">
                    {user.verified === Constants.USER.VERIFIED.APPROVED && (
                      <>
                        <div className="cursor-pointer" onClick={() => toogleStatus(user)}>
                          {user.status && user.status !== Constants.USER.STATUS.DISABLED ? (
                            <span className="badge badge--success">{t('common.globals.user_status.active')}</span>
                          ) : (
                            <span className="badge badge--danger">{t('common.globals.user_status.inactive')}</span>
                          )}
                        </div>
                        <img
                          className="action-icon"
                          src={Trash}
                          alt=""
                          onClick={() => deleteUser(user)}
                        />
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="no-items">{t('common.tech.no_users_found')}</div>
        )}
      </div>

      {!!users.length && (
        <Pagination
          active={pagination.page}
          pages={pagination.last_page}
          onChange={goToPage}
        />
      )}
    </div>
  )
}

export default Technicians;