import { Api } from 'utils';

class ServiceService {

	static get = async (params = {}) => Api.createResponse('services/get',params);
	static create = async (params = {}) => Api.createResponse('services/create',params);
	static update = async (params = {}) => Api.createResponse('services/update',params);
	static view = async (params = {}) => Api.createResponse('services/view',params);
	static delete = async (params = {}) => Api.createResponse('services/delete',params);

	static createEquipment = async (params = {}) => Api.createResponse('services/create-equipment',params);
	static updateEquipment = async (params = {}) => Api.createResponse('services/update-equipment',params);
	static deleteEquipment = async (params = {}) => Api.createResponse('services/destroy-equipment',params);

	static equipmentServiceView = async (params = {}) => Api.createResponse('services/equipment-service/view',params);
}

export default ServiceService;