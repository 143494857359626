import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Input } from 'components';
import { AuthService } from 'services';
import { Globals } from 'utils';
import { ArrowLeft, RecoveryEmail } from 'assets/icons';

const RecoverEmail = () => {

  const history = useHistory();
  const [formData, setFormData] = useState({ email: '' });

  const onChange = (target: keyof typeof formData, value: string) => {
    setFormData(s => ({ ...s, [target]: value }))
  }

  const isValidForm = (): boolean => {
    const onError = (msg: string) => {
      Globals.showError(msg);
      return false;
    }

    if (!formData.email) return onError('El correo es obligatorio');

    return true;
  }

  const submit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!isValidForm()) return;

    try {
      const res: any = await AuthService.recoverEmail(formData);
      if (res?.error) return Globals.showError(res.error);

      history.push('/recover/code');

    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div id="recover">
      <div className="form-wrapper">
        <button type="button" className="go-back" onClick={() => history.goBack()}>
          <img src={ArrowLeft} alt="Volver" />
        </button>

        <div className="icon">
          <img src={RecoveryEmail} alt="Icono" />
        </div>

        <form onSubmit={submit}>

          <p className="info">Por favor ingrese el correo electrónico{'\n'}registrado y se le enviará un código</p>

          <Input
            placeholder="Correo"
            type="email"
            value={formData.email}
            onChange={v => onChange('email', v)}
          />

          <div className="submit">
            <button className="btn--primary" type="submit">
              <span>Enviar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default RecoverEmail;