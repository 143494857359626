/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { IServiceType } from 'types';
import { Search, Trash, Add, Edit } from 'assets/icons';
import { Modal, Pagination } from 'components';
import { ServiceService } from 'services';
import { Globals } from 'utils';
import CreateService from './CreateService';
import ServiceDetails from './ServiceDetails';
import CreateEquipment from './CreateEquipment';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const useFetch = () => {
  const { t } = useTranslation();
  const initialForm = {
    search: '',
    page: 1,
    per_page: 20,
    last_page: 1,
    item_count: 0,
  };

  const [data, setData] = useState<any[]>([]);
  const [equipments, setEquipments] = useState<any[]>([]);
  const [canFetch, setCanFetch] = useState(true);
  const [pagination, setPagination] = useState(initialForm);

  const debounceTime = 500;
  let debounce: any = null;

  const fetchData = async () => {
    if (!canFetch) return;

    try {
      const res: any = await ServiceService.get({
        page: pagination.page,
        per_page: pagination.per_page,
        search: pagination.search,
      });

      if (!res?.data?.services?.rows)
        return Globals.showError(t('common.globals.toast-error'));

      const last_page = Math.ceil((res?.data?.services?.count || 0) / pagination.per_page);
      setCanFetch(false);
      setPagination({
        ...pagination,
        item_count: res?.data?.services?.count,
        last_page,
      });
      setData(res.data.services.rows);
      setEquipments(res.data.equipments);

    } catch (error) {
      console.log(error);
      Globals.showError(t('common.globals.toast-error'));
    }
  }

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setPagination(initialForm);
    fetchData();
  }

  const prevPage = () => {
    if (pagination.page > 1) {
      setCanFetch(true);
      setPagination({ ...pagination, page: pagination.page + 1 });
    }
  }

  const nextPage = () => {
    if (pagination.page < pagination.last_page) {
      setCanFetch(true);
      setPagination({ ...pagination, page: pagination.page + 1 });
    }
  }

  const goToPage = (page: number) => {
    if (page >= 1 && page <= pagination.last_page && page !== pagination.page) {
      setCanFetch(true);
      setPagination({ ...pagination, page });
    }
  }

  const search = (search: string) => {
    if (debounce) clearTimeout(debounce);
    debounce = setTimeout(() => {
      setCanFetch(true);
      setPagination({ ...pagination, page: 1, search });
    }, debounceTime);
  }

  useEffect(() => {
    fetchData();
  }, [pagination]);

  return {
    services: data,
    equipments,
    pagination,
    nextPage,
    prevPage,
    goToPage,
    reload,
    search,
  }
}

const Services = () => {
  const { t } = useTranslation();

  const [modalCreate, setModalCreate] = useState(false);
  const [modalDetails, setModalDetails] = useState(false);
  const [modalProduct, setModalProduct] = useState(false);
  const { services, equipments, pagination, reload, goToPage, search } = useFetch();
  const [selectedService, setSelectedService] = useState<IServiceType>();

  const viewService = (service: IServiceType, details = false) => {
    setSelectedService(service);
    details
      ? setModalDetails(true)
      : setModalCreate(true);
  }

  const deleteService = (service: IServiceType) => {
    const doIt = async () => {
      try {
        const res: any = await ServiceService.delete({ id: service.id });
        if (!res) return Globals.showError();

        Globals.showSuccess(t('common.globals.service_deleted'));
        reload();

      } catch (error) {
        console.log(error);
        Globals.showError();
      }
    }

    Globals.confirm(t('common.globals.service-tech-confirm'), doIt);
  }

  const onCloseModal = (reloading: boolean) => {
    if (reloading) reload();
    if (!!selectedService) setSelectedService(undefined);
    setModalCreate(false);
    setModalDetails(false);
    setModalProduct(false);
  }

  return (
    <div id="admin-services">
      <Modal
        title={`${!!selectedService ? t('common.globals.edit') : t('common.globals.add')} ${t('common.globals.service')}`}
        visible={modalCreate}
        onClose={() => onCloseModal(false)}
      >
        <CreateService service={selectedService} onClose={onCloseModal} />
      </Modal>

      <Modal
        title={t('common.globals.equipments_registered')}
        visible={modalDetails}
        onClose={() => onCloseModal(false)}
        size="lg"
      >
        <ServiceDetails
          service={selectedService}
          onClose={onCloseModal}
          equipments={equipments}
        />
      </Modal>

      <Modal
        title={t('common.globals.equipments')}
        visible={modalProduct}
        onClose={() => onCloseModal(false)}
      >
        <CreateEquipment onClose={onCloseModal} />
      </Modal>

      <div className="search-bar">
        <div>
          <span className="input-label">{t('common.globals.search')}</span>
          <div className="search-filter" style={{ width: '40%' }}>
            <img src={Search} alt=""/>
            <input
              placeholder={t('common.globals.search_by_name') || ''}
              onChange={v => search(v.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="custom-table w-75 m-auto">
        <div className="header">
          <span className="title">{t('common.service.data-registered')}</span>
          <div className="actions">
            <button className="btn--primary" onClick={() => setModalCreate(true)}>
              <img src={Add} width={24} alt="" style={{ marginRight: 10 }} />
              <span>{t('common.service.new_service')}</span>
            </button>
            <button className="btn--primary" onClick={() => setModalProduct(true)}>
              <img src={Add} width={24} alt="" style={{ marginRight: 10 }} />
              <span>{t('common.service.new_equipment')}</span>
            </button>
          </div>
        </div>

        {!!services.length ? (
          <table>
            <thead>
              <tr>
                <th>{t('common.service.service_type')}</th>
                <th className="right">{t('common.service.actions')}</th>
              </tr>
            </thead>
            <tbody>
              {services.map((service, index) => (
                <tr key={`row-${index}`}>
                  <td>
                    <span
                      className="text-blue cursor-pointer"
                      onClick={() => viewService(service, true)}
                    >
                      { service.name }
                    </span>
                  </td>
                  <td className="row-actions right">
                    <img
                      className="action-icon"
                      src={Edit}
                      alt=""
                      onClick={() => viewService(service)}
                    />
                    <img
                      className="action-icon"
                      src={Trash}
                      alt=""
                      onClick={() => deleteService(service)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="no-items">{t('common.service.not_data_found')}</div>
        )}
      </div>

      {!!services.length && (
        <Pagination
          active={pagination.page}
          pages={pagination.last_page}
          onChange={goToPage}
        />
      )}
    </div>
  )
}

export default Services;